import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { createAuth0 } from '@auth0/auth0-vue';
import configAuth from '../src/utils/auth_config';
import { createI18n } from 'vue-i18n';
import Donut from 'vue-css-donut-chart';
import 'vue-css-donut-chart/dist/vcdonut.css';
import './styles/styles.scss';
import 'devextreme/dist/css/dx.light.css';
import { loadMessages } from 'devextreme/localization/';
import LanguageSwitch from './components/language-switch/LanguageSwitch.vue';
import { loggerMixin } from './utils/loggerMixin';

import en from './locales/en.js';
import es from './locales/es.js';

loadMessages({'en': en,'es': es});

const i18n = createI18n({
    locale: 'es',
    fallbackLocale: 'es',
    messages:{
        en,
        es,
      },
});
createApp(App)
    .use(
        createAuth0({
            domain: configAuth.domain,
            client_id: configAuth.clientId,
            redirect_uri: window.location.origin,
            useRefreshTokens: configAuth.useRefreshTokens,
            audience: configAuth.audience,
            serverUrl: configAuth.serverUrl,
        }),
    )
    .mixin(loggerMixin)
    .use(store)
    .use(router)
    .use(Donut)
    .use(i18n)
    .component('LanguageSwitch', LanguageSwitch)
    .mount('#app');
