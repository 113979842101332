export default {
    general: {
        welcome: 'Bienvenido a LUCY',
        errorTenant: 'La organización es requerida',
        placeholderTenant: 'Selecciona tu organización',
        labelTenant: 'Selecciona la cuenta a la que deseas ingresar',
        errorNoData: 'No existen datos para mostrar',
        requireSearchData: 'Que cliente buscas?',
        addFields: 'Agregar + campos',
        viewDetail: 'Ver Detalle',
        gridEmptyMessage: 'No has agregado ninguna información todavía',
        daysOfWeek: {
            monday: 'Lunes',
            tuesday: 'Martes',
            wednesday: 'Miércoles',
            thursday: 'Jueves',
            friday: 'Viernes',
            saturday: 'Sábado',
            sunday: 'Domingo',
        },
        operativeActivityState: {
            Assigned: 'Asignada',
            InProcess: 'En proceso',
            RequestForClarification: 'En espera de aclaración',
            AwaitingRevision: 'En espera de revisión',
            AuditedByLeader: 'Auditado por líder',
            AwaitingPreInvoice: 'En espera de prefactura',
            PreInvoiceInRevision: 'Prefactura en revisión',
            Billed: 'Facturada',
        },
        hours: ' horas',
        tradeAgreementType: {
            FeePerHour: 'Por hora',
            StaticFee: 'Monto Fijo',
            Retainer: 'Retainer',
            LandmarkByTypeActivity: 'Hitos por Actividades',
            LandmarkByEvent: 'Hitos por Eventos',
            LimitFee: 'Monto Tope',
            ByService: 'Por Servicio',
            NoBillable: 'No facturable',
            Mixed: 'Mixto',
            default: 'Sin Tipo',
            FeePerHourInterval: 'Por hora/rangos',
        },
        contactType: {
            billing: 'No es contacto',
            production: 'Facturación',
            comercial: 'Producción',
            notContact: 'Comercial',
        },
        save: 'Guardar',
        cancel: 'Cancelar',
        noApply: 'No Aplica',
        edit: 'Editar',
        cancelEdit: 'Cancelar edición',
        actions: 'Acciones',
        back: 'Atras',
        notifications: {
            title: 'Notificaciones',
            buttonReadAllNotifications: 'Marcar como leidas',
            tabUnseen: "No leídas",
            tabAll: "Todo",
            noData: "No tienes notificaciones en este momento",
            checkAllAsSeen: "Marcar como leídas",
            settings: "Configuración de notificaciones"
        },
        sideMenu: {
            logout: 'Cerrar sesión',
            optionsMenu: {
                dashboard: 'Panel de trabajo',
                settlement: 'Liquidación y facturación',
                documentationCenter: 'Centro de documentación',
                clients: 'Clientes',
                reports: 'Reportes',
                management: 'Administración',
            },
            platformMenu: {
                settings: 'Ajustes',
                help: 'Ayuda',
            },
        },
        months: {
            january: 'Enero',
            february: 'Febrero',
            march: 'Marzo',
            april: 'Abril',
            may: 'Mayo',
            june: 'Junio',
            july: 'Julio',
            august: 'Agosto',
            september: 'Septiembre',
            october: 'Octubre',
            november: 'Noviembre',
            dicember: 'Diciembre',
        },
    },
    'need-help': {
        title: '¿Necesitas Ayuda?',
        subtitle:
            'Por favor escribe tu correo electrónico con tu inquietud y nos comunicaremos contigo.',
        placeholderEmail: "ejemplo{'@'}gmail.com",
        labelEmail: 'Correo electrónico',
        errorEmail: 'El correo es requerido',
        errorTypeEmail: "El correo debe ser de tipo ejemplo{'@'}ejemplo.com",
        labelConcern: 'Inquietud',
        placeholderConcern: 'Escribe tu inquietud',
        errorConcern: 'La inquietud es requerida',
        sendButton: 'Enviar',
    },
    management: {
        title: 'Administración',
        subtitle:
            'Accede a todas las herramientas que te permitirán definir y gestionar la información, los servicios y la facturación de la empresa.',
        company: {
            titleHeader: 'Tabla de compañías',
            'dash-general': 'No has agregado ninguna compañía todavía',
            'title-button': 'Agregar compañía',
            'title-general-form': 'Creación de compañía',
            'title-update-general-form': 'Edición de compañía',
            'form-type-company': {
                'label-logo': 'Logo',
                'replace-copy-image-first': 'Sube un logo',
                'copy-image-second': 'o arrastra y suelta',
                formats: 'JPG, GIF, PNG peso máximo 5MB',
                placeholderName: 'Escribe el nombre de la empresa',
                labelName: 'Nombre',
                errorName: 'El nombre es requerido',
                placeholderUniqueIdentificationNumber:
                    'Escribe el documento tributario',
                labelUniqueIdentificationNumber: 'Documento tributario',
                errorUniqueIdentificationNumber:
                    'El documento tributario es requerido',
                placeholderPhone: 'Escribe el teléfono',
                labelPhone: 'Teléfono',
                errorPhone: 'El teléfono es requerido',
                errorPhoneNumeric: 'El teléfono debe ser solo números',
                placeholderAddress: 'Escribe la dirección',
                labelAddress: 'Dirección',
                errorAddress: 'La dirección es requerida',
                placeholderCountry: 'Selecciona país',
                labelCountry: 'País',
                errorCountry: 'El país es requerido',
                placeholderCity: 'Selecciona la ciudad',
                labelCity: 'Ciudad',
                errorCity: 'La ciudad es requerida',
                placeholderEmail: "ejemplo{'@'}gmail.com",
                labelEmail: 'Correo electrónico',
                errorEmail: 'El correo es requerido',
                labelMain: '¿Esta será la compañía principal?',
                cancelButton: 'Cancelar',
                saveButton: 'Guardar',
                updateButton: 'Actualizar',
                messageCreate: 'Su compañía ha sido creada',
                messageUpdate: 'Su compañía ha sido actualizada',
                messageError: 'Se presento el error numero',
            },
            system: {
                title: 'Editar sistema',
                messageUpdate: 'Las variables de sistema han sido actualizadas',
                messageError: 'Se presento el error numero',
            },
            currency: {
                'dash-currencies': 'No has agregado ninguna moneda todavía',
                messageCreate: 'Su moneda ha sido creada',
                messageUpdate: 'Su moneda ha sido actualizada',
                messageError: 'Se presento el error numero',
            },
            indexingUnit: {
                'dash-indexingUnit':
                    'No has agregado ninguna unidad de indexación todavía',
                messageCreate: 'Su unidad de indexación ha sido creada',
                messageUpdate: 'Su unidad de indexación ha sido actualizada',
                messageError: 'Se presento el error numero',
                mensajeErrorEquivalency:
                    'Debe agregar al menos una equivalencia',
            },
            tariff: {
                tabPanelOne: {
                    messageUpdate: 'Su categoría ha sido actualizada',
                    messageError: 'Se presento el error numero',
                    messageCreate: 'Su categoría ha sido creada',
                    messageDelete: 'Su categoría ha sido eliminada',
                    title: 'Categorías',
                    nameLabel: 'Nombre',
                    namePlaceHolder: 'Ingresa el nombre de la categoría',
                    nameError: 'Debes ingresar un nombre para tu categoría',
                    descriptionLabel: 'Descripción',
                    descriptionPlaceHolder:
                        'Ingresa una descripción para la categoría',
                    descriptionError:
                        'Debes ingresar una descripción para tu categoría',
                    valuesLabel: '{value}',
                    valuesPlaceHolder: 'Ingresa el valor de {value}',
                    valuesError: 'Debes ingresar el valor de {value}',
                },
                tabPanelTwo: {
                    messageUpdate: 'Su libro de precio ha sido actualizado',
                    messageError: 'Se presento el error numero',
                    messageCreate: 'Su libro de precio ha sido creado',
                    messageDelete: 'Su libro de precio ha sido eliminado',
                    title: 'Libros de precios',
                    columns: {
                        column1: 'Título',
                        column2: 'Descripción',
                        column3: 'Tipo',
                        column4: 'Practicas / Unidades de servicio',
                        column5: 'Acciones',
                    },
                    types: {
                        byService: 'Por Servicio',
                        byHour: 'Por hora',
                    },
                    typeLabel: 'Tipo de libro',
                    typePlaceholder: 'Escoge el tipo de libro que deseas crear',
                    typeError: 'Debes escoger un tipo de libro',
                    categoryOrServiceLabel: 'Categoría/Servicio',
                    categoryOrServicePlaceHolder:
                        'Escoge una categoría o servicio',
                    categoryOrServiceError:
                        'Debes escoger una categoría o servicio',
                    titleLabel: 'Título',
                    titlePlaceholder: 'Ingresa un título para tu libro',
                    titleError: 'Debes ingresar un título para tu libro',
                    descriptionLabel: 'Descripción',
                    descriptionPlaceholder:
                        'Ingresa una descripción para tu libro',
                    descriptionError:
                        'Debes ingresar una descripción para tu libro',
                    titleCardWorkGroup: 'Grupos de trabajo',
                    onlyCategory: 'Categoría',
                    onlyArea: 'Práctica',
                    noArea: 'Práctica no encontrada',
                    onlySubArea: 'Unidad de Servicios',
                    noSubArea: 'Unidad de servicios no encontrada',
                },
                tabPanelThree: {
                    messageUpdate: 'Su tipo de gasto ha sido actualizado',
                    messageError: 'Se presento el error numero',
                    messageCreate: 'Su tipo de gasto ha sido creado',
                    messageDelete: 'Su tipo de gasto ha sido eliminado',
                    title: 'Tipos de gasto',
                    columns: {
                        column1: 'Título',
                        column2: 'Descripción',
                        column3: 'Código',
                        column4: 'Tipo',
                    },
                    nameLabel: 'Título',
                    namePlaceholder: 'Ingresa un título para tu tipo de gasto',
                    nameError: 'Debes ingresar un título para tu tipo de gasto',
                    descriptionLabel: 'Descripción',
                    descriptionPlaceholder:
                        'Ingresa una descripción para tu tipo de gasto',
                    descriptionError:
                        'Debes ingresar una descripción para tu tipo de gasto',
                },
                tabPanelFour: {
                    messageUpdate: 'Su impuesto ha sido actualizado',
                    messageError: 'Se presento el error numero',
                    messageCreate: 'Su impuesto ha sido creado',
                    messageDelete: 'Su impuesto ha sido eliminado',
                    title: 'Impuestos',
                    columns: {
                        column1: 'Nombre',
                        column2: 'Valor',
                    },
                    nameLabel: 'Nombre de Impuesto',
                    namePlaceholder: 'Ingresa un nombre para el impuesto',
                    nameError: 'Debes ingresar un nombre para el impuesto',
                    taxError: 'Debes ingresar un nombre para el impuesto',
                    placeHolderTaxType: 'Selecciona el tipo de impuesto',
                    labelTaxType: 'Tipo de impuesto',
                    valueLabel: 'Valor(%)',
                    valuePlaceholder: 'Ingresa un valor para el impuesto',
                    valueError: 'Debes ingresar un valor para el impuesto',
                    valueError2:
                        'El valor del impuesto debe ser estar entre 0.000001 y 100',
                    descriptionLabel: 'Descripción',
                    descriptionPlaceholder:
                        'Ingresa una descripción para tu tipo de gasto',
                    descriptionError:
                        'Debes ingresar una descripción para tu tipo de gasto',
                },
                tabPanelFive: {
                    messageUpdate: 'Su perfil tributario ha sido actualizado',
                    messageError: 'Se presento el error numero',
                    messageCreate: 'Su perfil tributario ha sido creado',
                    messageDelete: 'Su perfil tributario ha sido eliminado',
                    title: 'Perfiles tributarios',
                    columns: {
                        column1: 'Nombre',
                        column2: 'Descripción',
                        column3: 'Impuestos',
                        column4: 'Activo',
                        column5: 'Acciones',
                    },
                    nameLabel: 'Nombre',
                    namePlaceholder: 'Ingresa un nombre de perfil',
                    nameError: 'Debes ingresar un nombre para el perfil',
                    descriptionLabel: 'Descripción',
                    descriptionPlaceholder: 'Ingresa una descripción',
                    descriptionError:
                        'Debes ingresar una descripción para el perfil',
                    activeLabel: 'Activo',
                    selectPlaceholder: 'Seleccione un impuesto',
                    selectLabel: 'Impuestos',
                    taxesGridLabel: 'Impuestos del perfil',
                    profileBondedToClientError:
                        'No se puede desactivar debido a que el perfil tributario está asociado a un cliente.',
                    profileWithSameNameError:
                        'Lo sentimos. Ya existe un perfil tributario con el nombre que se quiere crear.',
                    profileWithNoTaxesError:
                        'Debe haber al menos un impuesto asociado al perfil tributario.',
                },
            },
            notifications: {
                messageSaveSuccess: 'Se ha guardado correctamente.',
                notificationsUsersTitle:
                    'Notificaciones asociadas a asignación de trabajo de usuarios',
                notificationsUsersParagraph:
                    'A continuación encontrarás las notificaciones para la asignación de trabajo para los usuarios:',
                notificationsJobTitle:
                    'Notificaciones asociadas a la gestión del usuario',
                notificationsJobParagraph:
                    'A continuación encontrarás las notificaciones para la gestión de trabajo:',
                notificationsProyectTitle:
                    'Notificaciones asociadas a proyectos',
                notificationsProyectParagraph:
                    'A continuación encontrarás las notificaciones para la gestión de proyectos:',
                notificationsFlowTitle: 'Notificaciones asociadas a flujos',
                notificationsFlowParagraph:
                    'A continuación encontrarás las notificaciones para la gestión de flujos:',
            },
        },
        system: {
            labelTimeZone: 'Zona horaria',
            placeholderTimeZone: 'Selecciona tu zona horaria',
            errorTimeZone: 'La zona horaria es requerida',
            labelLanguage: 'Idioma',
            placeholderLanguage: 'Selecciona tu idioma',
            errorLanguage: 'El idioma es requerido',
            placeholderDailyWorkedHours:
                'Escribe la meta diaria de horas trabajadas',
            labelDailyWorkedHours: 'Meta diaria de horas trabajadas',
            errorDailyWorkedHours:
                'La meta diaria de horas trabajadas es requerida',
            errorDailyWorkedHoursNumber:
                'La meta diaria de horas trabajadas solo permite números',
            placeholderDailyBilledHours:
                'Escribe la meta día de horas facturadas',
            labelDailyBilledHours: 'Meta diaria de horas facturadas',
            errorDailyBilledHours:
                'La meta diaria de horas facturadas es requerida',
            errorDailyBilledHoursNumber:
                'La meta diaria de horas facturadas solo permite números',
            placeholdertypeRestrictionActivityTime: 'Selecciona la restricción',
            labeltypeRestrictionActivityTime:
                'Restricción de ingreso y edición de trabajo',
            labeltypeRestrictionActivityTimeValueWeek: 'Semanal',
            labeltypeRestrictionActivityTimeValueDay: 'Día',
            labeltypeRestrictionActivityTimeValueMonth: 'Mensual',
            labeltypeRestrictionActivityTimeValueNotRestriction:
                'No tiene restricción',
            errortypeRestrictionActivityTime:
                'La restricción de ingreso y edición de trabajo es requerida',
            all: 'No tiene restricción',
            day: 'Día',
            weekly: 'Semanal',
            monthly: 'Mensual',
            titleDaysForm: 'Días laborales',
            dayOne: 'Lunes',
            dayTwo: 'Martes',
            dayThree: 'Mieroles',
            dayFour: 'Jueves',
            dayFive: 'Viernes',
            daySix: 'Sábado',
            daySeven: 'Domingo',
            placeholderdeliveryTime: 'Selecciona el tipo de fecha de entrega',
            labeldeliveryTime: 'Entrega fin del día',
            errordeliveryTime: 'La entrega fin del día es requerida',
            endDeliveryActivity: 'Fecha de entrega a la hora exacta',
            endDeliveryActivityDay: 'Fecha de entrega al final del día',
            placeholdertypeBillableTime:
                'Selecciona el tipo de tiempo a facturar',
            labeltypeBillableTime: 'Tiempo facturable',
            labeltypeBillableTimeOptionTimeBillable: 'Tiempo trabajado',
            labeltypeBillableTimeOptionTimeEstimated: 'Tiempo Estimado',
            errortypeBillableTime: 'El tipo de tiempo facturable es requerido',
            estimatedTime: 'Tiempo estimado',
            usedTime: 'Tiempo trabajado',
            placeholderDailyHourRegistrationTimeLimit:
                'Ingresa el límite de tiempo de registro',
            labelDailyHourRegistrationTimeLimit: 'Límite de tiempo de registro',
            dailyHourRegistrationTimeLimit:
                'El tiempo límite de registro es requerido',
            placeholderinitialWorkHour: 'Ingresa la hora de inicio laboral',
            labelinitialWorkHour: 'Hora de inicio laboral',
            placeholderfirstEndHour: 'Proxima a vencerse',
            labelfirstEndHour: 'Rango de oportunidad próxima a vencerse',
            errorfirstEndHour:
                'el rango de oportunidad próxima a vencerse es requerido',
            placeholdersecondEndHour: 'Vencida',
            labelsecondEndHour: 'Rango de oportunidad vencida',
            placeholderProcessFunction: 'Casos',
            labelProcessFunction: 'Inclusión de casos',
            optionProcessOne: 'Si',
            optionProcessTwo: 'No',
            errorsecondEndHour: 'el rango de oportunidad vencida es requerido',
            placeholderHoursTimeAlertActivitiesRevision:
                'El Tiempo alerta revisión de actividades es requerido',
            labelHoursTimeAlertActivitiesRevision:
                'Tiempo alerta revisión de actividades (Horas)',
            errorHoursTimeAlertActivitiesRevision:
                'El Tiempo alerta revisión de actividades es requerido',
            errorinitialWorkHour: 'La hora de inicio laboral es requerida',
            deliveryTime: 'Eltiempo de entrega fin es requerido',
            preselectedResponsableUserPlaceholder: 'Selecciona...',
            preselectedResponsableUserLabel: 'Auto asignación de responsable por defecto en actividades',
            preselectedResponsableUserOptionOne: 'Si',
            preselectedResponsableUserOptionTwo: 'No'
        },
        currency: {
            labelActiveCurrency: 'Estado',
            labelDefaultCurrency: 'Moneda Principal',
        },
        indexAndUnit: {
            labelActive: 'Estado',
            labelName: 'Nombre',
            labelSymbol: 'Abreviatura',
            labelStandard: 'Moneda',
            labelValue: 'Valor',
            labelDate: 'Fecha',
            labelDeleteListIndexing: 'Equivalencias',
        },
        'functional-structure': {
            'modules-type': {
                WorkFlow: ' este flujo',
                Activity: ' esta actividad',
                User: ' este usuario',
                Company: ' esta compañía',
                Files: ' una actualización por registro de archivo',
                Undefined: ' No definido',
                CreatedState: '- Ha creado',
                proyects: ' este proyecto',
                currency: ' la moneda',
                indexunit: ' la unidad de Indexación',
                practice: ' la práctica ',
                serviceunit: ' la unidad de servicio ',
            },
            'dash-practice': 'No tienes ningún área de práctica creada todavía',
            'dash-service-units':
                'No tienes ninguna unidad de servicios creada todavía',
            'dash-activities': 'No has agregado ninguna actividad todavía',
            areYouSureConfirm: '¿Seguro que quieres eliminar?',
            areYouSureConclusion:
                'Una vez eliminada no podrás recuperar esta actividad',
            cannotCloseActivity:
                'Esta actividad no se puede trabajar porque el flujo al que pertenece esta cerrado',
            cannotCloseWorkflowTitle: 'No se puede eliminar el flujo',
            cannotCloseWorkflowSubtitle:
                'ya tiene actividades asignadas o descargadas',
            confirmCloseWorkflowTitle: '¿Seguro que quieres finalizar?',
            confirmDeleteWorkflowTitle: '¿Seguro que quieres eliminar?',
            confirmCloseWorkflowSubtitle:
                'Recuerda que estas finalizando, no guardando',
            confirmDeleteWorkflowSubtitle:
                'Una vez eliminado no podrás recuperar este flujo',
            confirmExitTitle: '¿Seguro que quieres salir?',
            confirmExitSubtitle:
                'Tienes archivos cargados sin vincular al flujo, guarda para anexarlos',
            tooltipSaveWorkflowFiles:
                'Guarda para vincular los archivos cargados al flujo',
            tooltipSaveWorkflowFilesToNext:
                'Guarda para vincular los archivos cargados al flujo y poder ingresar a la pestaña de actividades',
            titleHeader: 'Tabla actividades',
            placeholderSearch: 'Buscar',
            placeholderBilling: 'Busca por cargo, tarea, nombre o empresa',
            'general-title-type-activity': 'Crea o edita una actividad',
            'title-type-activity': 'NUEVA ACTIVIDAD',
            'subtitle-type-activity': 'Tipo de actividad',
            'form-type-activity': {
                labelNameArea: 'Práctica',
                placeholderNameArea: 'Selecciona la práctica',
                errorNameArea: 'La práctica es requerida',
                labelNameSubArea: 'Unidad de servicios',
                placeholderNameSubArea: 'Selecciona la unidad',
                errorNameSubArea: 'La unidad de servicios es requerida',
                labelNameActivity: 'Nombre',
                placeholderNameActivity: 'Escribe el nombre',
                errorNameActivity: 'El nombre de la actividad es requerido',
                labelCode: 'Código',
                placeholderCode: 'Código',
                labelEstimatedTime: 'Tiempo estimado',
                placeholderEstimatedTime: 'Escribe las horas',
                errorEstimatedTime: 'Solo se permiten números',
                labelAveragingTimeUsed: 'Tiempo promedio utilizado',
                placeholderAveragingTimeUsed: 'Horas promedio',
                labelGroupCaption: 'Rango de oportunidad',
                labelFirstEndHour: 'Próxima a vencer',
                placeholderFirstEndHour: 'Escribe las horas',
                errorFirstEndHour: 'Solo se permiten números',
                labelLastEndHour: 'Vencida',
                placeholderLastEndHour: 'Escribe las horas',
                errorLastEndHour: 'Solo se permiten números',
                labelAppointmentSchedule: 'Agenda citación',
                labelRequiredDocumments: 'Requiere documento',
                labelRequiredRevision: 'Requiere revisión',
                labelExclusiveFlows: 'Exclusiva para flujos',
                labelDescriptionActivity: 'Descripción',
                placeholderDescriptionActivity:
                    'Por favor describe brevemente la actividad.',
                cancelButton: 'Cancelar',
                saveButton: 'Guardar',
                updateButton: 'Actualizar',
                messageCreate: 'Su tipo de actividad ha sido creado',
                messageUpdate: 'Su tipo de actividad ha sido actualizado',
                messageError: 'Se presento el error numero',
                labelIsBillable: 'Es facturable',
                labelButtonSubtheme: 'Agregar subtemas',
                labelNewSubtheme: 'Agregar nuevo subtema',
                updateRatebook:
                    'Para terminar la parametrización de este tipo de actividad debe ser incluida en los libros de precios correspondientes.',
                buttonOneVerifyRateBook: 'Actualizar libros de precios',
            },
            'form-activity': {
                placeholderNameProject: 'Selecciona la razón social',
                labelNameProject: 'Razón social / Proyecto',
                errorNameProject: 'La razón social es requerida',
                placeholderNameArea: 'Selecciona la práctica',
                labelNameArea: 'Práctica',
                errorNameArea: 'La práctica es requerida',
                placeholderNameSubArea: 'Selecciona la unidad de servicios',
                labelNameSubArea: 'Unidad de servicios',
                errorNameSubArea: 'La unidad de servicios es requerida',
                placeholderNameTypeActivity: 'Selecciona la actividad',
                labelNameTypeActivity: 'Actividad',
                errorNameTypeActivity: 'El tipo de actividad es requerido',
                placeholderDescriptionActivity:
                    'Por favor describe brevemente la actividad.',
                labelDescriptionActivity: 'Descripción',
                errorDescriptionActivity: 'La descripción es requerida',
                dividerText:
                    'También puedes activar el cronómetro o ingresar el tiempo manualmente.',
                timeWorked: 'Tiempo trabajado',
                minutes: '0 Minutos',
                hours: '0 Horas',
                personCharge: 'Responsable de la actividad',
                placeholderPersonCharge: 'Juan Carlos Pérez',
                startDate: 'Fecha de inicio',
                placeholderStartDate: 'Seleccione la fecha',
                startDelivery: 'Fecha de entrega',
                placeholderStartDelivery: 'Seleccione la fecha',
                estimatedTime: 'Tiempo estimado',
                estimatedTimeMinutes: '0 Minutos',
                estimatedTimeHours: '0 Horas',
            },
            'indexing-forms': {
                titlebutton: 'Agregar unidad de indexación',
                titleindexingForm: 'Unidad de Indexación',
                subtitleIndexingForm: 'Crear Nueva Unidad',
                submoduleTitle: '',
                placeholderName: 'Escribe el nombre de la unidad',
                labelName: 'Nombre de la unidad',
                placeholderEquivalencia: 'Agregar Equivalencia',
                labelEquivalencia: 'Agregar Equivalencia',
                placeholderStandar: 'Selecciona la moneda ',
                labelStandar: 'Moneda',
                placeholderAbreviatura: 'Escribe la abreviatura',
                labelAbreviatura: 'Abreviatura',
                errorName: 'El Nombre de la unidad es requerida',
                errorabreviatura: 'El Nombre de la abreviatura es requerida',
                errormoneda: 'El campo moneda debe ser seleccionado',
                errorPattern: '',
                mensajecreate: 'Unidad de servicio ha creada correctamente',
                mensajeError: 'No se pueden realizar el proceso',
                mensajecreacion: 'Debe agregar al menos una equivalencia',
                labelIsAccumulative: '¿Es acumulable?',
            },
            practice: {
                titlebutton: 'Agregar Práctica',
                titleHeader: 'Tabla de Prácticas',
                titleForm: 'Creación de Práctica',
                subtitleForm: 'Crear Nueva Práctica',
                submoduleTitle: '',
                placeholderName: 'Escribe el nombre de la Práctica',
                labelName: 'Nombre del Práctica',
                placeholderDescription: 'Agrega una descripción de la Práctica',
                labelDescription: 'Descripción de la Práctica',
                errorName: 'El Nombre de la Práctica es requerida',
                errorPattern: '',
                messageCreate: 'La práctica ha sido creada',
                messageUpdate: 'La práctica ha sido actualizada',
                messageError: 'No se puede guardar la Práctica',
                responsable: 'Responsables de las prácticas',
                responsableError:
                    'Los responsables de las prácticas son requeridos',
                labelCompany: 'Compañía',
                labelResponsable: 'Responsable',
                labelCountResponsable: 'Número de Responsables',
                labelSubAreas: 'SubAreas',
                labelActive: 'Estado',
                labelListResponsable: 'Responsables',
                practiceDeactivationWarning:
                    'Existen usersWithThisArea usuarios y proyectsWithThisArea proyectos asociados a esta práctica',
            },
            ServiceUnit: {
                titlebutton: 'Agregar unidades de servicio',
                titleHeader: 'Tabla de unidades de servicio',
                titleForm: 'Creación de unidades de servicios',
                subtitleForm: 'Crear nueva unidad de servicios',
                submoduleTitle: '',
                placeholderName:
                    'Escribe el nombre de las unidades de servicio',
                labelName: 'Nombre de las unidades de servicio',
                placeholderDescription:
                    'Agregar una descripción de las unidades de servicio',
                labelDescription: 'Descripción de las unidades de servicio',
                labelActive: 'Activo',
                errorName: 'El Nombre de las unidades de servicio requerida',
                errorPattern: '',
                responsable: 'Responsables de las unidades de servicio',
                labelArea: 'Práctica',
                area: 'La práctica es requerida',
                nameSubarea: 'El nombre de la unidad de servicio es requerida',
                descriptionsubarea:
                    'La descripción de la unidad de servicio es requerida',
                responsableError:
                    'Los responsables de las unidades de servico son requeridos',
                messageCreate: 'La unidad de servicio ha sido creada',
                messageUpdate: 'La unidad de servicio ha sido actualizada',
                messageError: 'No se puede guardar la unidad de servicio',
                labelNameServiceUnit: 'Nombre de la unidad de servicio',
                labelDescripcionServiceUnit:
                    'Descripción de la unidad de servicio',
                labelCountServiceUnit: 'Número de Responsables',
                labelStateServiceUnit: 'Estado de la unidad de servicio',
                labelListResponsablesSubAreas: 'Responsables',
                labelNamePractice: 'Práctica',
                labelPractice: 'Práctica',
                labelActiveSubArea: 'Estado de la unidad de servicio',
            },
            titleFlows: 'Flujos',
            'dash-flows': 'No has agregado ningún flujo todavía',
            'title-button-add-flows': 'Agregar flujo',
            'title-button-add-more-fields': 'Agregar + campos',
            'form-flows': {
                titleFlow: 'Crea un flujo',
                titleFlowUpdate: 'Edita un flujo',
                subtitleFlow: 'NUEVO FLUJO',
                submoduleTitle: 'General',
                placeholderNameArea: 'Selecciona la práctica',
                labelNameArea: 'Práctica',
                errorNameArea: 'La práctica es requerida',
                placeholderNameSubArea: 'Selecciona la unidad',
                labelNameSubArea: 'Unidad de servicios',
                errorNameSubArea: 'La unidad de servicios es requerida',
                placeholderNameFlow: 'Escribe el nombre del flujo',
                labelNameFlow: 'Nombre',
                errorNameFlow: 'El nombre del flujo es requerido',
                placeholderCode: 'Código',
                labelCode: 'Código',
                placeholderTypeForm: 'Selecciona tipo de formulario dinámico',
                labelTypeForm: 'Formulario dinámico',
                errorTypeForm: 'El formulario dinámico es requerido',
                placeholderDescriptionActivity:
                    'Por favor describe brevemente el flujo.',
                labelDescriptionActivity: 'Descripción',
                labeltitleNewFlow: 'Creación de nuevo flujo',
                cancelButton: 'Cancelar',
                saveButton: 'Crear flujo',
                updateFlow: 'Actualizar flujo',
                viewFlow: 'Ver flujo',
                updateButton: 'Guardar',
                messageCreate: 'Su tipo de flujo ha sido creado',
                messageUpdate: 'Su tipo de flujo ha sido actualizado',
                messageError:
                    'No se puede guardar el diagrama, esto puede ser causado por que no existe una actividad en el mismo o falta conectarla',
                messageNoHours:
                    'No puedes finalizar la actividad si no haz ingresado algun tiempo trabajado',
            },
            'dash-alerts': {
                titeHeader: 'Alertas de flujos',
                emptyState: 'No has agregado ninguna alerta todavía',
                addButton: 'Agregar alerta',
            },
            'general-alerts': {
                name: 'Nombre',
                methodSend: 'Método de envío',
                placeholderMethodSend: 'Selecciona los métodos de envío',
                placeholderName: 'Escribe el nombre de la notificación',
                typeNotification: 'Tipo de notificación',
                placeholderType: 'Selecciona un tipo de notificación',
                placeholderDescription: 'Escribe una descripción de la alerta',
                description: 'Descripción',
                errorName: 'El título es requerido',
                errorTypeSend: 'El método de envío es requerido',
                errorTypeNotification: 'El tipo de alerta es requerido',
                messageSaveUpdateSuccess:
                    'La alerta ha sido actualizada con éxito',
                messageSaveCreateSuccess: 'La alerta ha sido creada con éxito',
                subtitleAlert: 'Nueva Alerta',
                submoduleTitle: 'Notificación',
                placeholderDays: 'Escribe los días...',
                requiredDays: 'Los días son requeridos',
                placeholderActivity: 'Selecciona la actividad...',
                requiredActivity: 'El tipo de actividad es requerido',
                placeholderDate: 'Selecciona la fecha...',
                requiredDate: 'La fecha es requerida',
                conditionLabel: 'Condición',
                typeOne: {
                    one: 'Faltan',
                    two: 'día(s) para la fecha de entrega de la actividad',
                    three: 'del flujo',
                },
                typeTwo: {
                    one: 'Han pasado',
                    two: 'día(s) de la fecha de',
                    three: 'de la actividad',
                    four: 'del flujo',
                },
                typeThree: {
                    one: 'Hoy se cumple la fecha de entrega de la actividad',
                    two: 'del flujo',
                },
                initialDate: 'Fecha de inicio',
                deliveryDate: 'Fecha de entrega',
                downloadDate: 'fecha de finalización',
                typeOneNotification:
                    'Faltan X días para la fecha de entrega de la actividad X del flujo.',
                typeTwoNotification:
                    'Han pasado X días de la fecha X de la actividad X del flujo.',
                typeThreeNotification:
                    'Hoy se cumple la fecha de entrega de la actividad X del flujo.',
                email: 'Email',
                push: 'Push',
                title: 'Alertas',
                btnCancel: 'Cancelar',
                btnSave: 'Guardar',
            },
            'dash-dynamic-forms': 'No has agregado ningún formulario todavía',
            'title-button-dynamic-forms': 'Agregar formulario',
            'dynamic-form': {
                titleHeader: 'Tabla formularios dinámicos',
                titleDynamicForm: 'Formulario general',
                titleDynamicFormStepTwo: 'Crea un formulario dinámico',
                subtitleDynamicForm: 'NUEVO Formulario dinámico',
                submoduleTitle: 'Información general',
                titleCardDynamicForm: 'Tipos de flujos',
                placeholderName: 'Escribe el nombre del formulario',
                labelName: 'Nombre',
                errorName: 'El nombre es requerido',
                placeholderCodigo: '123',
                labelCodigo: 'Código',
                placeholderDescripcion: 'Describe brevemente el formulario',
                labelDescripcion: 'Descripción',
                titleColumnOne: 'Previsualización del formulario:',
                titleForm: 'Previsualización del formulario:',
                titleColumnTwo: 'Agregar campos personalizados:',
                subtitleColumnTwo: 'Haz click en el campo que necesites.',
                dxCheckBox: 'Selección',
                dxDateBox: 'Campo de fecha',
                dxNumberBox: 'Campo numérico',
                dxRadioGroup: 'Selección individual',
                dxSelectBox: 'Campo desplegable',
                dxSwitch: 'Activo / Inactivo',
                dxTextArea: 'Campo de párrafo',
                dxTextBox: 'Campo de línea de texto',
                backButton: 'Cancelar',
                nextButton: 'Siguiente',
                cancelButton: 'Cancelar',
                saveButton: 'Guardar',
                updateButton: 'Actualizar',
                deleteButton: 'Eliminar',
                placeholderTitle: 'Escribe el título del campo',
                labelTitle: 'Título',
                errorTitle: 'El título es requerido',
                placeholderTexto: 'Escribe el texto dentro del campo',
                labelTexto: 'Texto en vacío',
                labelCheckRequired: 'Campo obligatorio',
                placeholderMessaggeRequired: 'Escribe el mensaje de requerido',
                labelMessageRequired: 'Mensaje de error campo requerido',
                errorMessageRequired: 'Escribe el mensaje de campo requerido',
                labelCheckLength: 'Cantidad de caracteres',
                labelCheckMultiple: 'Selección multiple',
                placeholderMessaggeLength:
                    'Escribe el mensaje de error para cantidad de caracteres',
                placeholderLimitMultipleSelection:
                    'Limitar el numero de items seleccionados a:',
                labelMessageLength:
                    'Mensaje de error para cantidad de caracteres',
                labelLimitMultipleSelection:
                    'Indicar el número limite de items a seleccionar, 0 para ilimitado',
                placeholderMinLength:
                    'Escribe el mínimo de caracteres permitidos',
                labelMinLength: 'Minimo de caracteres',
                placeholderMaxLenght:
                    'Escribe el máximo de caracteres permitidos',
                labelMaxLenght: 'Máximo de caracteres',
                errorMinLength: 'El mínimo de caracteres es requerido',
                errorMin:
                    'El campo mínimo no puede tener un valor mayor al campo máximo',
                errorMax:
                    'El campo máximo no puede tener un valor menor al campo mínimo',
                errorMaxLenght: 'El máximo de caracteres es requerido',
                errorMessageLength:
                    'El mensaje de error de cantidad de caracteres es requerido',
                labelColumns: 'Selecciona la cantidad de columnas',
                labelDate: 'Selecciona el tipo de fecha',
                placeholderHeight: 'Digita la altura del campo de texto',
                labelHeight: 'Altura',
                onlyNumbers: 'Solo se permiten números',
                errorDynamicForm:
                    'Debes crear por lo menos un campo para el formulario dinámico',
                labelMask: 'Mascara',
                placeholderMask:
                    'Ingresa la expresión regular que validara la mascara',
                optionsList: 'Opciones separadas por comas',
                optionsDatasourceList: 'Listados del sistema',
                messageUpdate: 'El formulario dinamico ha sido actualizado',
                messageCreate: 'El formulario dinamico ha sido creado',
                messageError: 'No se puede guardar el formuario dinamico',
                dynamicFormHasWorkflows:
                    'No es posible desactivar un formulario dinamico con tipos de flujos asociados.',
            },
            'dash-users': 'No has agregado ningún usuario todavía',
            'title-button-users': 'Agregar usuario',
            'users-forms': {
                checkSuperAdmin: 'Super administrador',
                titleHeader: 'Tabla de usuarios',
                titleUsersForm: 'Crea o edita un usuario',
                subtitleUsersForm: 'NUEVO USUARIO',
                submoduleTitle: 'Información general',
                placeholderNameUsers: 'Escribe el nombre del usuario',
                labelNameUsers: 'Nombre',
                errorNameUsers: 'El nombre es requerido',
                errorPatternName:
                    'El nombre solo permite caracteres alfabéticos',
                labelLastName: 'Apellido',
                placeholderLastName: 'Escribe tu apellido',
                errorLastName: 'El apellido es requerido',
                errorPatternLastName:
                    'El apellido solo permite caracteres alfabéticos',
                placeholderDni: 'Usa números, letras y símbolos',
                labelDni: 'Número de identificación',
                errorDni: 'El número de identificación es requerido',
                placeholderCategory: 'Selecciona la categoría',
                labelCategory: 'Categoría',
                errorCategory: 'La categoría es requerida',
                placeholderCode: '123',
                labelCode: 'Código',
                placeholderAssociatedCompany: 'Selecciona la compañía',
                labelAssociatedCompany: 'Compañía',
                errorAssociatedCompany: 'La compañía es requerida',
                placeholderCountry: 'Selecciona el país',
                labelCountry: 'País',
                errorCountry: 'El país es requerido',
                placeholderCity: 'Selecciona la ciudad',
                labelCity: 'Ciudad',
                errorCity: 'La ciudad es requerida',
                placeholderWorkday: 'Selecciona la jornada',
                labelWorkday: 'Jornada laboral',
                errorWorkday: 'La jornada laboral es requerida',
                placeholderDailyGoalHoursWorked:
                    'Escribe la meta diaria de horas trabajadas',
                labelDailyGoalHoursWorked: 'Meta diaria de horas trabajadas',
                errorDailyGoalHoursWorked:
                    'La meta diaria de horas trabajadas es requerida',
                errorDailyGoalHoursWorkedNumber:
                    'La meta diaria de horas trabajadas solo permite números',
                placeholderDailyBillableHoursGoal:
                    'Escribe la meta día de horas facturables',
                labelDailyBillableHoursGoal: 'Meta diaria de horas facturables',
                errorDailyBillableHoursGoal:
                    'La meta diaria de horas facturables es requerida',
                errorDailyBillableHoursGoalNumber:
                    'La meta diaria de horas facturables solo permite números',
                fullTime: 'Tiempo completo',
                halfTime: 'Medio tiempo',
                placeholderEmail: "ejemplo{'@'}gmail.com",
                labelEmail: 'Correo electrónico',
                errorEmail: 'El correo electrónico es requerido',
                placeholderRestriction: 'Selecciona la restricción',
                labelRestriction: 'Restricción de ingreso y edición de trabajo',
                errorRestriction:
                    'La restricción de ingreso y edición de trabajo es requerida',
                all: 'No tiene restricción',
                day: 'Día',
                weekly: 'Semanal',
                monthly: 'Mensual',
                titleDaysForm: 'Días laborales',
                dayOne: 'Lunes',
                dayTwo: 'Martes',
                dayThree: 'Miércoles',
                dayFour: 'Jueves',
                dayFive: 'Viernes',
                daySix: 'Sábado',
                daySeven: 'Domingo',
                titleCardWorkGroup: 'Grupos de trabajo',
                titleCardPractice: 'Práctica y unidad de servicios',
                titleCardUsers: 'Usuarios bajo supervisión',
                titleCardRoles: 'Roles asignados',
                backButton: 'Cancelar',
                nextButton: 'Guardar',
                cancelButton: 'Cancelar',
                viewButton: 'Ver como',
                saveButton: 'Guardar',
                updateButton: 'Guardar',
                placeholderDateOfAdmission: 'Ingresa la fecha de ingreso',
                labelDateOfAdmission: 'Fecha de ingreso',
                errorDateOfAdmission: 'La fecha de ingreso requerida',
                messageCreate: 'El usuario fue creado correctamente',
                messageUpdate: 'El usuario ha sido actualizado correctamente',
                messageError: 'Se presento el error numero',
                labelPositionUser: 'Posición',
                labelJobAssignment: 'Días Laborables',
                labelWorkingEdition: 'no se sabe',
                labelSubAreas: 'Prácticas',
                labelWithoutRestrictions: 'No tiene restricciones',
                labelMonthly: 'Mensual',
                labelWeekly: 'Semanal',
                labelDaily: 'Día',
                labelTrue: 'Verdadero',
                labelFalse: 'Falso',
                labelFullTime: 'Tiempo Completo',
                labelHalfTime: 'Medio Tiempo',
                generalTab: 'General',
                workTab: 'Trabajo',
            },
            'dash-work-groups': 'No has agregado grupos de trabajo todavía',
            'title-button-work-groups': 'Agregar grupos de trabajo',
            'work-groups-forms': {
                titleWorkGroup: 'Crea un grupo de trabajo',
                externalUserPlaceHolder: 'Apoyo administrativo',
                titleHeader: 'Tabla grupos de trabajo',
                titleWorkGroupsForm: 'Crea o edita un grupo de trabajo',
                subtitleWorkGroupsForm: 'NUEVO GRUPO DE TRABAJO',
                submoduleTitle: 'Información general',
                placeholderNameWorkGroups: 'Escribe el nombre',
                labelNameWorkGroups: 'Nombre',
                errorNameWorkGroups: 'El nombre es requerido',
                placeholderCode: '123',
                labelCode: 'Código',
                placeholderDescriptionWorkGroup:
                    'Por favor describe brevemente el grupo de trabajo.',
                labelDescriptionWorkGroup: 'Descripción',
                errorDescriptionWorkGroup:
                    'Debes ingresar una descripción para el grupo de trabajo',
                stepTwoSubmoduleTitle: 'Integrantes',
                updateButton: 'Actualizar',
                viewButton: 'Ver',
                cancelButton: 'Cancelar',
                saveButton: 'Guardar',
                messageUpdate: 'El grupo de trabajo ha sido actualizado',
                messageCreate: 'El grupo de trabajo ha sido creado',
                messageError: 'Se presento el error numero',
            },
            'dash-roles': 'No has agregado ningún rol todavía',
            'duplicate-rol': 'Copia',
            'title-button-roles': 'Agregar rol',
            'roles-forms': {
                titleHeader: 'Tabla roles',
                titleRolesForm: 'Crea o edita roles',
                subtitleRolesForm: 'NUEVO rol',
                submoduleTitle: 'Información general',
                placeholderNameRoles: 'Escribe el nombre del rol',
                labelNameRoles: 'Nombre',
                errorNameRoles: 'El nombre es requerido',
                placeholderCode: '123',
                labelCode: 'Código',
                placeholderDescriptionWorkGroup:
                    'Por favor describe brevemente el rol.',
                labelDescriptionWorkGroup: 'Descripción',
                errorDescriptionRol: 'La descripción es requerida',
                titleCardPermissions: 'PERMISOS ASIGNADOS',
                messageUpdate: 'El rol ha sido actualizado',
                messageCreate: 'El rol ha sido creado',
                messageError: 'Se presento el error numero',
                labelPermissions: 'Permisos',
            },
            'shotdown-roles': {
                labelDefault: '¿Seguro que quieres modificar?',
            },
            'workflows-diagrama-component': {
                label: 'Etiqueta',
                link: {
                    tooltip: 'Etiquetas del enlace',
                    text: 'Texto de etiqueta',
                },
                rectangle: {
                    isInLoop: '¿Esta Actividad está en un bucle?',
                    titleSection: 'Info Tipo de actividad',
                    isAutoAssignable: '¿Es auto asignable?',
                    stage: 'Etapa',
                    time1: 'Horas de aviso luego de vencida',
                    time2: 'Horas de aviso luego de terminada',
                },
                polygon: {
                    titleSection: 'Información general',
                    desitionText: 'Texto de Decisión',
                },
                configuration: 'Configuración',
                ellipse: {
                    beginTime: 'Tiempo Inicial',
                },
            },
        },
        project: {
            titleHeader: 'Tabla de proyectos',
            'dash-projects': 'No has agregado ningún proyecto todavía',
            'title-button-projects': 'Crear proyecto',
            'title-button-add-projects': 'Agregar + campos',
            'projects-forms': {
                titleProjectsForm: 'Crea o edita un proyecto',
                subtitleProjectsForm: 'NUEVO PROYECTO',
                submoduleTitle: 'Información general',
                submoduleTitleStepTwo: 'Integrantes y privacidad',
                submoduleTitleStepThree: 'Contrato y acuerdo comercial',
                placeholderNameProject: 'Escribe el nombre del proyecto',
                labelNameProject: 'Nombre de proyecto',
                errorNameProject: 'El nombre es requerido',
                placeholderCompany: 'Selecciona el cliente',
                labelCompany: 'Cliente',
                errorCompany: 'La compañía es requerida',
                placeholderDescriptionProject:
                    'Por favor describe brevemente el proyecto',
                labelDescriptionProject: 'Descripción',
                errorDescriptionProject: 'La descripción es requerida',
                placeholderRequestedBy: 'Selecciona el contacto del cliente',
                labelRequestedBy: 'Solicitado por',
                errorRequestedBy: 'Solicitado por es requerida',
                titleCardArea: 'PRÝCTICA',
                titleCardSubAreas: 'UNIDAD DE SERVICIOS',
                copyImageFirst: 'Sube un documento',
                copyImageSecond: 'o arrastra y suelta',
                formats: 'DOCX, XLSX, PPTX o PDF hasta de 250MB',
                labelCardsMenbers: 'Integrantes',
                titleCardCommercialManager: 'Encargados comerciales',
                titleCardFidelizadores: 'Fidelizadores',
                titleCardLoyaltyGroup: 'Grupo de trabajo',
                labelCardsPrivacy: 'Privacidad',
                titleCardWorkGroups: 'Grupos de trabajo',
                titleCardUsers: 'Usuarios',
                errorCommercialManager:
                    'Los encargados comerciales son obligatorios',
                errorLoyaltyGroup: 'El grupo de trabajo es obligatorio',
                backButton: 'Cancelar',
                nextButton: 'Guardar',
                cancelButton: 'Cancelar',
                saveButton: 'Guardar',
                updateButton: 'Guardar',
                emptyPractice:
                    'Se debe seleccionar por lo menos una práctica o unidad de servicios',
                messageCreate: 'El proyecto ha sido creado',
                messageUpdate: 'El proyecto ha sido actualizado',
                messageError: 'Se presento el error numero',
                labelClientName: 'Cliente',
                labelUserSolicited: 'Solicitado Por',
                labelFilesProject: 'Archivos del proyecto',
                labelSubAreas: 'Prácticas y Unidades de servicios',
                labelWorkGroupAssing: 'Grupo de trabajo',
                labelCommercialUsers: 'Encargados comerciales',
                labelExternalMembersGroup: 'Grupos de Miembros Externos',
                labelRetiredMembersPrivacy: 'Privacidad de Miembros Retirados',
                labelUserComercials: 'Encargados comerciales',
                labelGroupWorks: 'Grupo de trabajo',
                labelPrivacityMemberRetired: 'Privacidad de Miembros Retirados',
                labelWorkGroupExtern: 'Grupos de Miembros Externos',
                labelModuleMembersPrivacy: 'Integrantes y Privacidad',
                labelActiveProject: 'Estado',
                labelTrue: 'True',
                labelFalse: 'False',
                labelActive: 'Activo',
                labelInactive: 'Inactivo',
                labelProyectFields: 'Formulario dinámico',
            },
            invoiceDash: 'Aun no existen facturas asociadas a este proyecto',
            'trade-agreement': {
                titleHeader: 'Tabla de acuerdos comerciales',
                dash: 'No has agregado ningún contrato todavía',
                buttonAdd: 'Agregar Contrato',
                'trade-agreement-form': {
                    titleFirstForm: 'INFORMACIÓN CONTRACTUAL',
                    titleSecondForm: 'INFORMACIÓN ACUERDO COMERCIAL',
                    titleThirdForm: 'EXPORTACIÓN LEDES',
                    placeholderStartDate: 'Selecciona la fecha',
                    labelStartDate: 'Fecha de inicio',
                    errorStartDate: 'La fecha de inicio es requerida',
                    placeholderFinalDate: 'Selecciona la fecha',
                    labelFinalDate: 'Fecha final',
                    errorFinalDate: 'La fecha de finalización es requerida',
                    placeholderIndexingUnit: 'Selecciona tipo de moneda',
                    labelIndexingUnit: 'Moneda / Unidad de indexación',
                    errorIndexingUnit:
                        'La moneda o unidad de indexación es requerida',
                    placeholderDescriptionProject:
                        'Por favor describe brevemente el proyecto',
                    labelDescriptionProject: 'Descripción',
                    errorDescription: 'La descripción es requerida',
                    rateTypeOne: 'Libro de precios',
                    rateTypeTwo: 'Tarifa fija',
                    includeTopHoursLabel: '¿Incluye monto tope de horas?',
                    topHoursLabel: 'Monto tope de horas',
                    includeAccumulativeLabel: '¿Horas acumulativas?',
                    includeAccumulativePlaceholder:
                        'Selecciona si los rangos son acumulativos',
                    rateTypeThree: 'Rangos',
                    labelIntervaleOne: 'Valor inferior (horas)',
                    labelIntervaleTwo: 'Valor superior (horas)',
                    labelPeriodTime: 'Vigencia',
                    placeHolderPeriodTime:
                        'Selecciona la vigencia de los rangos',
                    typeOnePeriod: 'Mensual',
                    typeTwoPeriod: 'Bimensual',
                    typeThreePeriod: 'Trimensual',
                    typeFourPeriod: 'Semestral',
                    typeFivePeriod: 'Anual',
                    intervalsDefLabel: 'Configuración de rangos',
                    valueHours: 'Número de horas',
                    defaultValueLabel:
                        'El valor de cobro por defecto, en caso de superar los rangos configurados',
                    includeTopHoursPlaceholder:
                        'Selecciona si incluye monto de horas',
                    includeOptionOne: 'Si',
                    includeOptionTwo: 'No',
                    TopValueHoursLabel: 'Monto de horas máximo',
                    placeholderTopHourValue: 'Ingresa el tope de horas',
                    labelRateType: 'Asigna un valor por cada hora que cargues.',
                    placeholderCurrency: 'Selecciona una moneda',
                    labelCurrency: 'Moneda',
                    placeholderRate: 'Tarifa benchmark',
                    labelRate: 'Tarifa',
                    labelAllowWorkRegistration:
                        '¿Se permite el registro de trabajo una vez se consumio todas las bolsas de dinero?',
                    placeholderHourValue: '1.000.000',
                    labelHourValue: 'Valor Hora',
                    placeholderInvoicedIn: 'Selecciona una moneda',
                    labelInvoicedIn: 'Este proyecto será facturado en',
                    labelLedes: 'Permitir exportar formato LEDES',
                    placeholderObservationsProject:
                        'Por favor escribe comentarios o sugerencias al respecto.',
                    labelObservationsProject: 'Observaciones',
                    placeholderMonthAmount: '1.000.000',
                    labelMonthAmount: 'Valor mensualidad',
                    labelMonthAmountRetainer: 'Valor Mensualidad',
                    labelMonthAmountCapAmount: 'Valor Contrato',
                    placeholderHourAmountAverage: '$',
                    labelHourAmountAverage: 'Valor de horas esperado',
                    labelIsDownPayment: '¿Es una mensualidad anticipada?',
                    labelIsValueMonthVariable: '¿Es una mensualidad variable?',
                    labelFixedAmount:
                        'Asigna un monto fijo por atender un proyecto sin importar las horas que este se demore.',
                    labelRetainerOne:
                        'Establece un monto específico para una cantidad de horas asignadas.',
                    labelRetainerTwo:
                        'Las horas adicionales se cobrarán de acuerdo a la tarifa que hayas definido.',
                    labelHoursAvailableOne: 'Horas disponibles',
                    labelHoursAvailableTwo:
                        'Escoge la vigencia de las horas disponibles. Podrá ser por la vigencia del contrato o mensual.',
                    year: 'Vigencia del contrato',
                    month: 'Mensual',
                    placeholderValidity: '5 horas *',
                    labelState: 'Estado',
                    labelAdditionalHours: 'Cobro de horas adicionales',
                    labelOtherTariff:
                        'Tarifas Aplicables para descuento de monto',
                    labelAccumulation: 'Acuerdo de acumulación',
                    noAccumulation: 'No acumulable',
                    accumulationByMonth: 'Acumulable por mes',
                    accumulationByYear: 'Acumulable por vigencia del contrato',
                    labelDistribution: 'Acuerdo de Distribucion de monto',
                    distributionMonth: 'Distribucion Mensual',
                    distributionTotal: 'Distribucion por vigencia de contrato',
                    labelMilestones:
                        'Define un monto y asigna la fecha en que se realizará el cobro o define un monto a cobrar cuando una actividad se descargue.',
                    milestonesTypeOne: 'Hito por fecha',
                    milestonesTypeTwo: 'Hito por actividad',
                    errorEmptyGrid:
                        'Debe de haber por lo menos un registro en la tabla',

                    labelPerHour: 'Asigna un valor por cada hora que cargues',
                    labelCapMountOne:
                        'Asigna una monto total del que se irán descontando cobros por hora o por servicio.',
                    labelCapMountTwo:
                        'Se aplicará un descuento si se excede el monto tope que definiste.',
                    labelCapMountThree:
                        'Tarifa aplicable después de consumir todas las bolsas de dinero.',
                    labelByServiceOne:
                        'Asigna un valor neto por cada actividad que cargues.',
                    labelMixedOne:
                        'Libro de precios principal (cobro por servicio)',
                    labelMixedTwo:
                        'Libro de precios secundario (cobro por hora)',
                    typeRatebookOne: 'Por hora',
                    typeRatebookTwo: 'Por servicio',
                    noTypeRatebook: 'Sin cobro',

                    labelNotBillableOne:
                        'Haz un control del dinero o las horas pendientes despues de terminado un contrato. Recuerda que las horas que registres aquí no van a generar ningún cobro',
                    labelDateNotBillable: 'Fecha de inicio',
                    placeholderDateNotBillable: 'Selecciona una fecha',
                    labelValueNotBillable: 'Valor',
                    placeholderValueNotBillable: '$100.000',
                    labelHourAvailable: 'Horas disponibles',
                    placeholderHourAvailable: 'Ejemplo: 5 horas',
                    typeNotBillableOne: 'Por monto',
                    typeNotBillableTwo: 'Por horas',
                    typeNotBillableThree: 'No aplica',
                    renewalContractLabel: 'Renovación automática',
                    takeLastReferenceDateLabel: 'Incremento inicio de año',
                },
            },
            activities: {
                titleHeader: 'Tabla actividades por proyecto',
                dash: 'No has agregado ninguna actividad todavía',
                buttonAdd: 'Agregar actividad',
                buttonFilter: 'Filtrar actividades',
            },
            bills: {
                dash: 'No has agregado ningún gasto todavía',
                buttonAdd: 'Agregar gasto',
                'bills-form': {
                    messageCreate: 'Su gasto ha sido creado',
                    messageUpdate: 'Su gasto ha sido actualizado',
                    messageError: 'Se presento el error numero',
                    titleBillsForm: 'Gastos',
                    placeholderBills: 'Selecciona el gasto',
                    labelBills: 'Selecciona el gasto',
                    errorBills: 'El gasto es requerido',
                    placeholderCurrency: 'Selecciona una moneda',
                    labelCurrency: 'Moneda',
                    errorCurrency: 'La moneda es requerida',
                    placeholderBillValue: '$0',
                    labelBillValue: 'Ingresa el valor',
                    errorBillValue: 'El valor es requerido',
                    errorBillZero: 'El valor debe ser diferente de cero',
                    placeholderDescriptionBill:
                        'Por favor describe brevemente la actividad.',
                    labelDescriptionBill: 'Descripción',
                    placeholderDateBill: 'Selecciona la fecha',
                    labelDateBill: 'Fecha',
                    errorDateBill: 'La fecha del gasto es requerida',
                    expenseSupport: 'Cargar soporte',
                    copyImageFirst: 'Sube un archivo',
                },
            },
            notifications: {
                hours: 'Notificaciones de horas',
                staticFee: 'Notificaciones de monto fijo',
                retainer: 'Notificaciones de retainer',
                landmarkByTypeActivity:
                    'Notificaciones de hitos por tipo de actividad',
                landmarkByEvent: 'Notificaciones de hitos por evento',
                limitFee: 'Notificaciones de monto tope',
                byService: 'Notificaciones de servicios',
                noBillable: 'Notificaciones no facturables',
                messageEmptyState:
                    'Este acuerdo comercial no cuenta con notificaciones',
            },
            'shotdown-project': {
                label: 'El proyecto ha sido desactivado correctamente',
                labelError: 'No se ha podido desactivar el proyecto',
                msgDeactivateError:
                    'No es posible desactivar el rol, está asignado a uno o mas usuarios.',
                msgDeactivateCurrency:
                    'Debes asignar otra moneda como principal, para poder desactivar esta moneda',
                msgEditTradeAgreement:
                    ' Para editar el acuerdo comercial; debes activar el proyecto asociado',
                msgContactUpdated: 'Contacto actualizado exitosamente',
                msgContactsWarning:
                    'Este contacto puede estar enlazado a uno o más clientes. Si se desactiva se podrían perder los enlaces y los respectivos permisos. Continuar?',
            },
        },
        client: {
            titleHeader: 'Tabla clientes',
            dash: 'No has agregado ningún cliente todavía',
            create: {
                title: 'Crea un cliente',
                titleEdit: 'Edita un cliente',
                newClient: 'NUEVO CLIENTE',
                //
                labelNaturalPersonType: 'Persona Natural',
                labelJuridicPersonType: 'Persona Jurídica',
                errorPersonType: 'Se debe escoger un tipo de persona',
                placeholderNameClient: 'Escribe el nombre del cliente',
                labelNameClient: 'Cliente / Nombre y apellidos',
                errorNameClient: 'El nombre del cliente es requerido',
                placeholderCode: '123',
                labelCode: 'Código',
                placeholderComercialName: 'Escribe el nombre comercial',
                labelComercialName: 'Nombre Comercial',
                errorComercialName: 'El nombre comercial es requerido',
                placeholderDocumentType: 'Selecciona el tipo de documento',
                labelDocumentType: 'Tipo de Documento',
                errorDocumentType: 'El tipo de documento es requerido',
                placeholderDocumentNumber: 'Escribe el número de documento',
                labelDocumentNumber: 'Número de documento',
                errorDocumentNumber: 'El número de documento es requerido',
                placeholderEmpresarialGroup: 'Selecciona el grupo empresarial',
                labelEmpresarialGroup: 'Grupo Empresarial',
                errorEmpresarialGroup: 'El grupo empresarial es requerido',
                placeholderDateVinculation: 'Selecciona la fecha',
                labelDateVinculation: 'Fecha de vinculación de la empresa',
                errorDateVinculation: 'La fecha de vinculación es requerida',
                placeholderComercialResponsable:
                    'Escribe el encargado comercial',
                labelComercialResponsable: 'Encargado comercial',
                errorComercialResponsable:
                    'El encargado comercial es requerido',
                placeholderFidelizador: 'Escribe el fidelizador',
                labelFidelizador: 'Fidelizador',
                errorFidelizador: 'El fidelizador es requerido',
                placeholderState: 'Habitual',
                labelState: 'Estado',
                placeholderCountry: 'Selecciona el país',
                labelCountry: 'País',
                errorCountry: 'El país es requerido',
                placeholderCity: 'Selecciona la ciudad',
                labelCity: 'Ciudad',
                errorCity: 'La ciudad es requerida',
                placeholderPostalCode: 'Escribe el Código Postal',
                labelPostalCode: 'Código Postal',
                errorPostalCode: 'El código postal es requerido',
                placeholderDirection: 'Escribe la Dirección',
                labelDirection: 'Dirección',
                errorDirection: 'La dirección es requerida',
                placeholderPhoneNumber: 'Escribe el número de contacto',
                labelPhoneNumber: 'Número de teléfono',
                errorPhoneNumber: 'El número de contacto es requerido',
                placeholderEmail: "ejemplo{'@'}gmail.com",
                labelEmail: 'Correo electrónico',
                errorEmail: 'El correo electrónico es requerido',
                errorNumberPermit:
                    'Este campo solo permite caracteres numéricos',
                errorEmailPermit: 'Este formato de email no está permitido',
                messageCreate: 'Su cliente ha sido creado',
                messageUpdate: 'Su cliente ha sido actualizado',
                messageError: 'Se presento el error numero',
                addMoreAddress: '+ Agregar dirección',
                addMorePhone: '+ Agregar teléfono',
                placeholderProfileTaxId: 'Selecciona el perfil tributario',
                labelProfileTaxId: 'Perfil tributario',
            },
            invoiceByClient: {
                dash: 'Aun no existen facturas asociadas a este cliente',
            },
            contact: {
                titleHeader: 'Tabla de contactos',
                title: 'Crea un contacto',
                titleEdit: 'Edita un contacto',
                newContact: 'NUEVO Contacto',
                placeholderNameClient: 'Escribe el nombre',
                labelNameClient: 'Nombre',
                errorNameClient: 'El nombre es requerido',
                placeholderLastName: 'Escribe el apellido',
                labelLastName: 'Apellido',
                errorLastName: 'El apellido es requerido',
                placeholderPosition: 'Escribe el cargo',
                labelPosition: 'Cargo',
                errorPosition: 'El cargo es requerido',
                placeholderDocumentNumber: 'Escribe el número de documento',
                labelDocumentNumber: 'Número de documento',
                errorDocumentNumber: 'El número de documento es requerido',
                placeholderBussinessName: 'Razón social 123',
                labelBussinessName: 'Razón social',
                placeholderCode: '123',
                labelCode: 'Código',
                placeholderComercialName: 'Escribe el nombre comercial',
                labelComercialName: 'Nombre Comercial',
                errorComercialName: 'El nombre comercial es requerido',
                placeholderDocumentType: 'Selecciona el tipo de documento',
                labelDocumentType: 'Tipo de Documento',
                errorDocumentType: 'El tipo de documento es requerido',
                placeholderEmpresarialGroup: 'Selecciona el grupo empresarial',
                labelEmpresarialGroup: 'Grupo Empresarial',
                errorEmpresarialGroup: 'El grupo empresarial es requerido',
                placeholderDateVinculation: 'Selecciona la fecha',
                labelDateVinculation: 'Fecha de vinculación de la empresa',
                errorDateVinculation: 'La fecha de vinculación es requerida',
                placeholderComercialResponsable:
                    'Escribe el encargado comercial',
                labelComercialResponsable: 'Encargado comercial',
                errorComercialResponsable:
                    'El encargado comercial es requerido',
                placeholderState: 'Habitual',
                labelState: 'Estado',
                placeholderCountry: 'Selecciona el país',
                labelCountry: 'País',
                errorCountry: 'El país es requerido',
                placeholderCity: 'Selecciona la ciudad',
                labelCity: 'Ciudad',
                errorCity: 'La ciudad es requerida',
                placeholderPostalCode: 'Escribe el Código Postal',
                labelPostalCode: 'Código Postal',
                errorPostalCode: 'El código postal es requerido',
                placeholderDirection: 'Escribe la Dirección',
                labelDirection: 'Dirección',
                errorDirection: 'La dirección es requerida',
                placeholderPhoneNumber: 'Escribe el número de contacto',
                labelPhoneNumber: 'Número de contacto',
                errorPhoneNumber: 'El número de contacto es requerido',
                placeholderEmail: "ejemplo{'@'}gmail.com",
                labelEmail: 'Correo electrónico',
                errorEmail: 'El correo electrónico es requerido',
                errorNumberPermit:
                    'Este campo solo permite caracteres numéricos',
                errorEmailPermit: 'Este formato de email no está permitido',
                labelType: 'Tipo de contacto',
                placeholderType: 'Selecciona un tipo de contacto',
                labelMain: '¿Este será el contacto principal?',
                messageUpdate: 'Su contacto ha sido actualizado',
                messageError: 'Se presento el error numero',
                messageCreate: 'Su contacto ha sido creado',
                dash: 'No has agregado ningún contacto todavía',
                dashClient: 'No has agregado ningún cliente todavía',
            },
        },
        menu: {
            stepOne: {
                name: 'Compañía',
                sideMenuByStep: {
                    subStepOne: 'General',
                    subStepTwo: 'Sistema',
                    subStepThree: 'Moneda',
                    subStepSFour: 'Unidades de Indexación',
                    subStepFive: 'Tarifas',
                    subStepSix: 'Notificaciones',
                    subStepSeven: 'APIs',
                    subStepEight: 'Carga De Archivos',
                },
            },
            stepTwo: {
                name: 'Estructura funcional',
                sideMenuByStep: {
                    subStepOne: 'Práctica',
                    subStepTwo: 'Unidades de servicio',
                    subStepThree: 'Actividades',
                    subStepFour: {
                        name: 'Flujos',
                        subMenus: {
                            subMenuOne: 'General',
                            subMenuTwo: 'Actividades',
                            subMenuThree: 'Alertas',
                        },
                    },
                    subStepFive: {
                        name: 'Formularios dinámicos',
                        subMenus: {
                            subMenuOne: 'Formulario general',
                            subMenuTwo: 'Diseño',
                        },
                    },
                },
            },
            stepThree: {
                name: 'Usuarios',
                sideMenuByStep: {
                    subStepOne: {
                        name: 'Usuarios',
                        subMenus: {
                            subMenuOne: 'General',
                            subMenuTwo: 'Perfil de trabajo',
                        },
                    },
                    subStepTwo: {
                        name: 'Grupos de trabajo',
                        subMenus: {
                            subMenuOne: 'General',
                            subMenuTwo: 'Integrantes',
                        },
                    },
                    subStepThree: {
                        name: 'Roles',
                    },
                },
            },
            stepFour: {
                name: 'Clientes',
                sideMenuByStep: {
                    subStepone: 'Clientes',
                    subStepTwo: 'Contactos',
                },
            },
            stepFive: {
                name: 'Proyectos',
                sideMenuByStep: {
                    subStepOne: 'Información general',
                    subStepTwo: 'Integrantes y privacidad',
                    subStepThree: 'Contrato y acuerdo comercial',
                    subStepFour: 'Actividades',
                    subStepFive: 'Notificaciones',
                },
            },
            stepSix: {
                name: 'Portal clientes',
                sideMenuByStep: {},
            },
        },
        general: {
            titleDinamicForms: 'Campos personalizados',
        },
        settings: {
            changedOrganization: 'Cambiar de Organización',
        },
    },
    register: {
        paragraph:
            'Con Lucy puedes administrar y gestionar los asuntos legales de cualquier empresa a través de:',
        paragraphTwo:
            'Lleva un control de los procesos, estructura la información y analízala ágilmente para que la empresa sea más eficiente y rentable.',
        itemOne: 'Personalización de actividades y flujos de trabajo.',
        itemTwo: 'Automatización de procesos.',
        itemThree: 'Carga automatizada de horas.',
        itemFour: 'Liquidación y orden de facturación.',
        itemFive: 'Reportes y seguimiento.',
        titleForm: 'Regístrate',
        redirectToLoginMessage: '¿Ya tienes una cuenta? ',
        redirectToLoginLink: 'Inicia sesión',
        userTitle: 'Usuario',
        labelName: 'Nombre',
        placeholderName: 'Escribe tu nombre del usuario',
        errorName: 'El nombre es requerido',
        errorPatternName: 'El nombre solo permite caracteres alfabéticos',
        labelLastName: 'Apellido',
        placeholderLastName: 'Escribe tu apellido',
        errorLastName: 'El apellido es requerido',
        errorPatternLastName: 'El apellido solo permite caracteres alfabéticos',
        labelEmail: 'Correo electrónico',
        placeholderEmail: "ejemplo{'@'}gmail.com",
        companyTitle: 'Compañía',
        labelNameCompany: 'Nombre compañía',
        placeholderNameCompany: 'Escribe el nombre de la compañía',
        errorNameCompany: 'El nombre de la compañía es requerido',
        labelCountry: 'País',
        placeholderCountry: 'Selecciona tu país',
        errorCountry: 'El país es requerido',
        labelCity: 'Ciudad',
        placeholderCity: 'Selecciona tu ciudad',
        errorCity: 'La ciudad es requerida',
        labelAddress: 'Dirección',
        placeholderAddress: 'Dirección de tu domicilio',
        errorAddress: 'La dirección del domicilio es requerida',
        labelPhone: 'Teléfono',
        placeholderPhone: 'Teléfono',
        errorPhone: 'El teléfono es requerido',
        errorNumericPhone: 'El teléfono debe tener solo numeros',
        labelTimeZone: 'Zona horaria',
        placeholderTimeZone: 'Selecciona tu zona horaria',
        errorTimeZone: 'La zona horaria es requerida',
        labelLanguage: 'Idioma',
        placeholderLanguage: 'Selecciona tu idioma',
        errorLanguage: 'El idioma es requerido',
        titleUploadImage: 'DALE UNA IMAGEN A TU COMPAÑÍA',
        subTitleUploadImage:
            'Una imagen que te represente o represente la compañía ayudará a las personas a reconocer fácilmente tu cuenta.',
        copyImageFirst: 'Sube una imagen',
        copyImageSecond: 'o arrastra y suelta',
        formats: 'JPG, GIF, PNG peso máximo 5MB',
        imageSuggestions: 'Tamaño recomendado: 171px * 51px',
        backButton: 'Anterior',
        nextButton: 'Siguiente',
        skipButton: 'Omitir',
        errorUploadFile: 'El tipo de archivo no está permitido',
        errorImageWeight: 'El archivo es demasiado grande',
        warningImageDimensions:
            'El archivo no cumple con el tamaño sugerido: 171*51. Puede continuar con el registro.',
    },
    production: {
        title: 'Panel de trabajo',
        subtitle:
            'Consulta indicadores y el estado de actividades, flujos y casos que tienes asignados, así como los de otros usuarios o equipos.',
        historyTitle: 'Bitácora',
        dashboard: {
            generalTitle: 'Panel de trabajo',
            generalSubTitle:
                'Consulta los indicadores y el estado de actividades, flujos y casos que han sido asignados.',
            filterObject: {
                day: 'Día',
                week: 'Semana',
                month: 'Mes',
                year: 'Año',
            },
            'dash-cases-ops': 'No tienes procesos registrados',
            'activities-summary': 'Resumen de actividades',
            'dash-activities-ops': 'No tienes actividades en curso',
            'dash-activities-ops-complete': 'No tienes actividades cerradas',
            'dash-workflows-open': 'No tienes flujos abiertos',
            'dash-workflows-close': 'No tienes flujos cerrados',
            titleHeader: 'Tabla de actividades',
            titleHeaderProcess: 'Actividades en curso',
            titleHeaderComplete: 'Actividades completadas',
            titleHeaderMigrated: 'Actividades migradas',
            filter: 'Filtros',
            filterTitle: 'FILTROS APLICADOS:',
            clearFilter: 'Limpiar filtros',
            openFilter: 'Filtros',
            filtersApplied: 'Filtros aplicados',
            activities: 'Actividades',
            newActivities: 'Nueva actividad',
            viewcanceButton: 'Cancelar ver como',
            allactivities: 'Actividades en curso',
            activitiescoplete: 'Actividades completadas',
            migratedActivities: 'Actividades migradas',
            case: 'Casos',
            newCase: 'Nuevo caso',
            allCases: 'Casos en curso',
            checkAllCases: 'Revisa los casos en curso',
            allCasesClosed: 'Casos cerrados',
            checkAllCasesClosed: 'Revisa los casos en cerrados',
            flows: 'Flujos',
            newFlows: 'Nuevo flujo',
            allFlows: 'Flujos en curso',
            allFlowsClosed: 'Flujos cerrados',
            flowTitle: 'Título del flujo',
            taskTitle: 'Tareas',
            taskSubTitle:
                'Selecciona una tarea y despliega su información detallada',
            card1: 'ACTIVIDADES A TIEMPO',
            card1Title: 'Actividades a tiempo',
            card2: 'ACTIVIDADES POR VENCERSE',
            card2Title: 'Actividades por vencerse',
            card3: 'ACTIVIDADES VENCIDAS',
            card3Title: 'Actividades vencidas',
            card4: 'ACTIVIDADES POR REVISAR',
            card4Title: 'Actividades por revisar',
            card4Tooltip:
                'Revisa las actividades de tu equipo. Este contador no se verá afectado la selección del filtro.',
            'dxScheduler-switcherWeek': 'My Week',
            'dxScheduler-switcherMonth': 'My Month',
            open: 'Abierto',
            close: 'Cerrado',
            tilteIndicators: 'Indicadores de gestión',
            indicator1: 'TIEMPO FACTURABLE',
            indicator1Tooltip:
                'Total de tiempo facturable de actividades descargadas en el rango de fechas seleccionado.',
            indicator2: 'TIEMPO NO FACTURABLE',
            indicator2Tooltip:
                'Suma del tiempo trabajado en las actividades no facturables mas la diferencia entre el tiempo facturado de las facturables.',
            indicator3: 'EFICIENCIA',
            indicator3Tooltip:
                'Actividades según el estado de oportunidad con fecha de finalización dentro del rango de fechas seleccionado.',
            indicator4: 'HISTÓRICO DE OPORTUNIDAD',
            indicator4Tooltip:
                'Actividades segun el estado de oportunidad con fecha de finalización en el rango de fechas seleccionado.',
            indicator5: 'ACTIVIDADES COMPLETADAS',
            indicator5Tooltip:
                'Número de actividades finalizadas dentro del rango de fechas seleccionado.',
            indicator6: 'META DE HORAS FACTURABLES',
            indicator6Tooltip:
                'Número de actividades finalizadas dentro del rango de fechas seleccionado.',
            indicator7: 'HORAS ASIGNADAS',
            indicator8: 'HORAS TRABAJADAS',
        },
        cases: {
            buttonAdd: 'Agregar caso',
            dash: 'No tienes casos agregados',
        },
        flows: {
            buttonAdd: 'Agregar Flujo',
        },
        'cases-details': {
            TitleFormCase: 'Detalle del caso',
            Description: 'Completa los campos del caso que vas a generar.',
            CaseTitleplaceholder: 'Escribe el título del caso',
            CaseTitlelabel: 'Título del caso',
            CaseTitleFormlabel: 'Título',
            CaseTitleError: 'Debes escribir el título del caso',
            CaseParts: 'Partes del caso',
            CaseDescriptionplaceholder: 'Por favor describe brevemente el caso',
            CaseDescriptionlabel: 'Descripción',
            tabPanel: {
                activities: { title: 'Actividades' },
                workflow: { title: 'Flujos' },
            },
            defendants: {
                buttonAdd: 'Agregar parte',
            },
            plaintiffs: {
                buttonAdd: 'Agregar contraparte',
            },
            CasePartsName: 'Nombre',
            CasePartsDocumentNumber: 'Número de documento',
            CounterCasePartsName: 'Nombre',
            CounterCasePartsDocumentNumber: 'Número de documento',
        },
        'activities-details': {
            title: 'Detalle de actividad',
            subTitle: 'Completa los campos de la actividad que vas a generar.',
            tabPanel: {
                general: {
                    copyChronometer:
                        'Puedes activar el cronómetro o ingresar el tiempo manualmente.',
                    TitleActivityForm: 'TÍTULO DE LA ACTIVIDAD',
                    title: 'General',
                    ActivityTitlelabel: 'Título de la actividad',
                    ActivityTitleplaceholder:
                        'Escribe el título de la actividad',
                    ActivityTitleError:
                        'Debes escribir el título de la actividad',
                    Projectlabel: 'Razón social / Proyecto',
                    ProjectPlaceHolder: 'Escoge la razón social / proyecto',
                    ProjectError: 'Debes escoger una razón social / proyecto',
                    APSUlabel: 'Actividad / Unidad de servicios / Práctica',
                    PSUlabel: 'Práctica / Unidad de servicios',
                    APSUplaceholder:
                        'Escoge una Actividad / Unidad de servicios / Práctica',
                    APSUerror:
                        'Debes escoger una Actividad / Unidad de servicios / Práctica',
                    Subtopicerror: 'Debes seleccionar un subtema',
                    Hourserror:
                        'Debes ingresar el número de horas del tiempo trabajado',
                    Minuteserror:
                        'Debes ingresar el número de minutos del tiempo trabajado',
                    Caselabel: 'Caso',
                    Caseplaceholder: 'Selecciona un caso',
                    Caseerror: 'Debes seleccionar un caso',
                    Subthemelabel: 'Subtema',
                    Subthemeplaceholder: 'Selecciona un subtema',
                    Subthemeerror: 'Debes seleccionar un subtema',
                    Descriptionlabel: 'Descripción',
                    Descriptionplaceholder:
                        'Por favor describe brevemente la actividad',
                    DescriptionError: 'Debes añadir una descripción',
                    Workedtimelabel: 'Tiempo trabajado',
                    WithoutTitle: 'ㅤ',
                    HoursPlaceHolder: 'Horas',
                    MinutesPlaceHolder: 'Minutos',
                    Workedtimeplaceholder: 'Tiempo trabajado',
                    Workedtimeerror: 'Añade el tiempo trabajado',
                    Billabletimelabel: 'Tiempo facturable',
                    Billabletimeplaceholder: 'Tiempo facturable',
                    Billabletimeerror: 'Añade el tiempo facturable',
                    Expectedtimelabel: 'Tiempo estimado',
                    Expectedtimeplaceholder: 'Tiempo estimado',
                    Expectedtimeerror: 'Añade el tiempo estimado',
                    Facturablelabel: 'Facturable',
                    Activityresponsablelabel: 'Responsable de la actividad',
                    messageLoading: 'Cargando',
                    Activityresponsableplaceholder:
                        'Responsable de la actividad',
                    ActivityResponsableError:
                        'Debes escoger un responsable de la actividad',
                    Startdatelabel: 'Fecha de inicio',
                    Startdateplaceholder: 'Fecha de inicio',
                    Startdateerror: 'Debes escoger una fecha de inicio',
                    Deliverdatelabel: 'Fecha de entrega',
                    Deliverdateplaceholder: 'Fecha de entrega',
                    Deliverdateerror: 'Debes escoger una fecha de entrega',
                    Downloaddatelabel: 'Fecha de finalización',
                    Downloaddateplaceholder: 'Fecha de finalización',
                    Downloaddateerror:
                        'Debes escoger una fecha de finalización',
                    Fileslabel: 'Adjuntar archivo',
                    Fileserror: 'Debes adjuntar un archivo',
                    messageCreate: 'Ha sido creada una nueva actividad',
                    messageUpdate: 'Ha sido actualizada la actividad',
                    messageDownload: 'Se ha finalizado la actividad',
                    messageApproval: 'Se ha aprobado la actividad',
                    messageShare: 'Se ha compartido la actividad correctamente',
                    workLabelCheck:
                        'Incluir el tiempo de la tarea en el total de la actividad',
                    typeMessageWork: 'Tipo de mensaje',
                    typeMessageWorkPlaceHolder: 'Selecciona el tipo de mensaje',
                    messageErrorShare:
                        'Error al intentar compartir esta actividad',
                    messageIncompleteTask:
                        'La actividad tiene tareas pendientes por lo que no se podrá finalizar',
                    messageRequireFiles:
                        'La actividad requiere que se cargue un archivo para finalizarla',
                    messageNoHours:
                        'No puedes finalizar la actividad si no haz ingresado algun tiempo trabajado',
                    messageNoUser:
                        'No puedes guardar una actividad sin asignar un responsable',
                    messageNoUserInGroup:
                        'No puedes guardar la actividad debido a que el responsable asignado no puede realizarla, debes asignar un nuevo responsable',
                    messageNoDateEnd:
                        'No puedes crear esta actividad pues el acuerdo comercial esta por vencer',
                    cannotDeactivateActivity:
                        'No puedes crear esta actividad pues el acuerdo comercial esta vencido',
                    messageNoDateEndStill:
                        'El acuerdo comercial esta por vencer',
                    messageErrorCustomFields:
                        'Uno o mas campos personalizados son requeridos',
                },
                expenses: {
                    title: 'Gastos',
                    placeholderExpense: 'Selecciona el gasto',
                    labelExpense: 'Selecciona el gasto',
                    errorExpense: 'Debes seleccionar un gasto',
                    placeholderCurrency: 'Moneda',
                    labelCurrency: 'Moneda',
                    errorCurrency: 'Debes seleccionar una moneda',
                    placeholderValue: '$COP',
                    labelValue: 'Ingresa el valor',
                    errorValue: 'Debes ingresar un valor',
                    placeholderDescription:
                        'Por favor describe brevemente el gasto',
                    labelDescription: 'Descripción',
                    errorDescription: 'Debes ingresar una descripción',
                    Fileslabel: 'Cargar soporte',
                    Datelabel: 'Fecha',
                    Dateplaceholder: 'Fecha',
                    Dateerror: 'Debes escoger una fecha',
                    messageCreate: 'Ha sido creado un nuevo gasto',
                    messageUpdate: 'Ha sido actulizado el gasto',
                    messageError: 'No ha sido posible procesar la solicitud',
                },
                share: {
                    title: 'Compartir',
                    Profesionallabel: 'Agrega el nombre del profesional',
                    Profesionalplaceholder: 'Selecciona el profesional',
                    Profesionalerror: 'Debes escoger un profesional',
                    ShareDisabledTooltip: 'No se puede compartir la actividad debido a que solo pertenece a este flujo'
                },
            },
            PopUpExpenses: {
                labelRateType:
                    'Selecciona las secciones a compartir de la actividad:',
                labelShareExpenses: 'Gastos',
                labelShareBillingNotes: 'Notas de facturación',
                subLabelShareBillingNotes:
                    'Agrega notas sobre fechas que se deban tener en cuenta o sobre cobros adicionales que no vayan dentro de la mensualidad.',
                emptyState: 'No se han agregado notas de facturación.',
                titleTabOne: 'Historial de notas',
                subTitleTabOne:
                    'Consulta todas las notas agregadas hasta ahora.',
                titleTabTwo: 'Agregar nota',
                subTitleTabTwo: 'Agrega una nota de facturación.',
                sublevelStrings: {
                    onTime: ' - A tiempo',
                    toBet: ' - Por vencerse',
                    expired: ' - Vencidas',
                },
            },
        },
        'workflows-details': {
            title: 'Detalle de flujo',
            subTitle:
                'Completa los campos de cada sección para generar el flujo',
            tabPanel: {
                general: {
                    title: 'General',
                    labelName: 'Cliente/Proyecto',
                    placeholderName: 'Escoge un cliente/proyecto',
                    errorName: 'Debes escoger un cliente/proyecto',
                    labelArea: 'Práctica',
                    placeholderArea: 'Escoge una práctica',
                    errorArea: 'Debes escoger una práctica',
                    labelSubArea: 'Unidad de servicios',
                    placeholderSubArea: 'Escoge una unidad de servicios',
                    errorSubArea: 'Debes escoger una unidad de servicios',
                    labelProcess: 'Caso',
                    placeholderProcess: 'Escoge un caso',
                    labelWorkflowType: 'Tipo de flujo',
                    placeholderWorkflowType: 'Escoge un tipo de flujo',
                    errorWorkflowType: 'Debes escoger un tipo de flujo',
                    labelWorkgroup: 'Equipo Responsable',
                    placeholderWorkgroup: 'Escoge un equipo responsable',
                    errorWorkgroup: 'Debes escoger un equipo responsable',
                    labelResponsable: 'Responsable',
                    placeholderResponsable: 'Escoge un responsable de Flujo',
                    errorResponsable: 'Debes escoger un responsable',
                    labelTitle: 'Título',
                    placeholderTitle: 'Escribe un título',
                    errorTitle: 'Debes ingresar un título',
                    labelDescription: 'Descripción',
                    placeholderDescription: 'Escribe una descripción',
                    errorDescription: 'Debes ingresar una descripción',
                    successOWF:
                        'El flujo operativo ha sido creado correctamente',
                    successDF:
                        'El formulario dinámico ha sido guardado correctamente',
                    successFlowClose:
                        'El flujo ha sido finalizado correctamente',
                    successFlowSave: 'El flujo ha sido guardado correctamente',
                    editingOnFormSucess: 'Cambios guardados correctamente',
                    errorWOValidation:
                        'No puedes crear flujos con acuerdos comerciales vencidos',
                    projectWithoutDescription: 'Proyecto sin descripción',
                    tooltipProjectTitle: 'Descripción:',
                    cannotCloseWorkflow:
                        'No puedes finalizar un flujo cuyas actividades están asignadas, en proceso, o pendientes por aclarar',
                },
                workflowType: {
                    title: 'Flujos y Actividades',
                },
                tradeAgreement: {
                    title: 'Acuerdo Comercial',
                    labelSelectTradeAgreement:
                        '¿Este flujo operativo de trabajo debe tener un acuerdo comercial propio?',
                    noDescription: 'Acuerdo comercial sin descripción',
                    tooltipTitle: 'Acuerdo comercial:',
                },
                dynamicForm: {
                    title: 'Formulario Dinámico',
                },
                expenses: {
                    title: 'Gastos',
                },
                uploadFile: {
                    fileNotFound: 'Archivo no encontrado',
                },
            },
        },
        'new-flow': {
            placeholderName: 'Selecciona la razón social',
            labelName: 'Razón social / Proyecto',
            errorName: 'La razón social es obligatoria',
            placeholderFlow: 'Selecciona el tipo de flujo',
            errorFlow: 'El tipo de flujo es obligatorio',
            labelFlow: 'Tipo de flujo / Práctica / Unidad de servicios',
            placeholderActivity: 'Selecciona una actividad',
            errorActivity: 'La actividad es obligatoria',
            labelActivity: 'Actividad / Práctica / Unidad de servicios',
            placeholderCase: 'Selecciona el caso',
            labelCase: 'Caso',
            placeholderTeam: 'Selecciona al equipo responsable',
            labelTeam: 'Equipo responsable',
            errorTeam: 'El equipo responsanble es obligatorio',
            placeholderResponsible: 'Selecciona al responsable',
            labelResponsible: 'Responsable',
            errorResponsible: 'El responsable es obligatorio',
            placeholderTitle: 'Título',
            labelTitle: 'Agregar título',
            errorTitle: 'El título es obligatorio',
            placeholderDescription: 'Por favor describe brevemente el flujo.',
            labelDescription: 'Descripción',
            buttonCompleteForm: 'Ir al formulario completo',
        },
        calendar: {
            title: 'Agenda',
            subTitle:
                'Visualiza el trabajo de la semana y agrega actividades a un día específico.',
        },
    },
    settlement: {
        auditedByLeader: {
            general: {
                reviewedServices: 'Servicios revisados',
                sendToAwaitingPreinvoice: 'Enviar pendientes por prefacturar',
                sendToAwaitingPreinvoiceSuccess:
                    'La operación de envío de actividades a prefactura ha sido completada con éxito, la operación se verá reflajada pronto',
                sendToAwaitingPreinvoiceError:
                    'La operación de envío de actividades a prefactura ha presentado un error',
                sendToAwaitingPreinvoiceNotAllowed:
                    'La fecha de vigencia para el acuerdo aún no permite enviar actividades a prefactura',
            },
            detail: {
                dateLabel: 'Fecha de corte:',
                client: 'Cliente:',
                numberOfActivities: 'Número de actividades:',
                project: 'Proyecto:',
                billableHours: 'Horas facturables:',
                changeClient: 'Cambiar de razón social',
                oabuAuditedByLeader: 'Actividades auditadas por el líder',
                billNotes: 'BillNotes',
            },
        },
        awaitingPreInvoice: {
            general: {
                draftPreinvoice: 'Borrador Prefactura',
                totals: 'Valores estimado por cobrar: ',
                totalsPreinvoice: 'Valores en dinero liquidado: ',
            },
            detail: {
                presentation: {
                    title: 'Carta de presentacion de la factura',
                    misterLabel: 'Señores',
                    letter: 'Adjuntamos la presente liquidación No. {numSettle} por la suma total de {totalAmount}, que corresponde a honorarios profesionales realizados desde {initialDate} hasta {endDate} y rendiciones de gastos de nuestro estudio que se detallan en la nota de cobro que se anexa a este documento.',
                    letterGOH:
                        'Adjuntamos la presente liquidación No. {numSettle} por la suma total de {totalAmount}, que corresponde a honorarios profesionales de asesoría legal causados desde {initialDate} hasta {endDate}.',
                },
                information: {
                    title: 'Información de la factura',
                    titleGOH: 'Información de la prefactura',
                    titleAttached: 'Información del anexo',
                    labels: {
                        endDate: 'Fecha de Corte: ',
                        project: 'Proyecto: ',
                        phone: 'Teléfono: ',
                        accountingDocument: 'Documento Contable',
                        settle: 'Liquidación: ',
                        accountingDocumentGOH: 'NIT: ', //Documento Contable
                        settleGOH: 'Factura: ', //Liquidación
                        billInGOH: 'Moneda en: ',
                        tradeAgreement: 'Acuerdo Comercial: ',
                        billIn: 'Factura en: ',
                        client: 'Cliente: ',
                        direction: 'Dirección: ',
                        exchange: 'Tipo de cambio: ',
                        rateAvarage: 'Tarifa efectiva por hora',
                        subTotal: 'Subtotal: ',
                        total: 'Total {unitOfPayment}',
                        totalTwo: 'Total:',
                        nonTaxes: 'Sin impuesto seleccionados:',
                        descriptionType: {
                            fees: 'Honorarios correspondientes al mes de ',
                            expenses: 'Gastos',
                        },
                        comment: 'Comentario',
                        taxes: 'Impuestos',
                        taxesTwo: 'Impuestos:',
                        ret: 'Retenciones',
                        retTwo: 'Retenciones:',
                        totalPay: 'Total a pagar',
                        sendToPreinvoice: 'Enviar a prefactura',
                        sendToPreinvoiceApart: 'Enviar a prefactura (Separado)',
                        addComment: 'Adicionar comentario',
                        sendToPreinvoiceSuccess:
                            'La operacion de creacion de prefactura se ha completado con exito',
                        sendToPreinvoiceError:
                            'La operacion de creacion de prefactura ha presentado un error',
                        sendToPreinvoiceChargeError:
                            'No se puede crear una prefactura sin una unidad de cobro',
                        shutDownPreinvoiceSuccess:
                            'La operacion de apagado ha sido completada con exito, la operacion se vera reflajada pronto',
                        shutDownPreinvoiceError:
                            'La operacion de apagado ha teniado un error',
                    },
                },
                support: {
                    title: 'Soporte Factura',
                    additionalCost: 'Cobros Adicionales',
                    addProyect: 'Agregar Proyecto',
                },
                remember: {
                    title: 'Recuerda que puedes enviar una copia al encargado comercial o cliente antes de enviar la factura.',
                    toLeader: 'Enviar copia al encargado comercial',
                    toClient: 'Envia copia al cliente',
                },
                texts: {
                    noCategory: 'Sin Categoría',
                    noWorkflow: 'Sin Flujo',
                    activity: 'Actividad',
                    workflow: 'Flujo',
                    project: 'Proyecto',
                    showSummary:
                        '¿Mostrar informacion de resumen en prefactura?',
                    showProjects:
                        '¿Mostrar informacion de proyectos en prefactura?',
                    showProfesionals:
                        '¿Mostrar informacion de profesionales en prefactura?',
                    showActivities:
                        '¿Mostrar informacion de actividades en prefactura?',
                    showExpenses:
                        '¿Mostrar informacion de gastos en prefactura?',
                    professionalsLabel: 'Profesionales',
                    professionalsEmptyGrid: 'Sin profesionales para mostrar',
                    activitiesLabel: 'Actividades',
                    activitiesEmptyGrid: 'Sin actividades para mostrar',
                    descriptionLabel: 'Descripción',
                    additionalCommentsLabel: 'Comentarios Adicionales',
                    saveLabel: 'Guardar',
                    cancelLabel: 'Cancelar',
                    shareLabel: 'Enviar',
                    changeLabel: 'Cambiar',
                    acceptLabel: 'Aceptar',
                    exportToExcel: 'Exportar a Excel',
                    projectslabel: 'Proyectos',
                    projectsEmptyGrid: 'Sin proyectos para mostrar',
                    selectAtLeastOneProject:
                        'Debe seleccionar uno o mas proyectos a liquidar',
                    projectsMustBeSameClient:
                        'Los proyectos seleccionados deben pertenecer al mismo cliente',
                    projectsMustBeSameTradeAgreement:
                        'Los proyectos seleccionados deben tener el mismo tipo de acuerdo comercial',
                    projectsMustBeSameCurrency:
                        'Los proyectos seleccionados deben ser cobrados y facturados en la misma moneda',
                    atLeastOneBlockSelected:
                        'Debe seleccionar al menos un bloque para apagar',
                },
            },
        },
        preinvoice: {
            sendDetailSAP: '¿Deseas enviar el detalle de esta factura a SAP?',
            preinvoiceState: 'Estado de Prefactura',
            preinvoiceUpdatedMessage: 'La prefactura ha sido actualizada',
            preinvoiceUpdatedFailedMessage:
                'Ocurrió un error al actualizar la prefactura',
            preinvoiceApproved: 'Se han aprobado las prefacturas',
            state: {
                created: 'Creado',
                revision: 'En revision',
                approved: 'Aprobado',
                canceled: 'Cancelado',
            },
            creationDateSinceLabel: 'Fecha creación desde',
            creationDateUntilLabel: 'Fecha creación hasta',
            StateLabel: 'Estado',
            preinvoiceCopied: '¡Copiada factura para cliente!',
            sendPreinvoicesTitle: 'Aprobar prefacturas',
            mustSelectPreinvoice:
                'Debes seleccionar una o más prefacturas a liquidar',
            total: 'Total',
            subTotal: 'Subtotal',
            sendPreinvoiceToBill: 'Enviar a factura',
            createPDF: 'Generar PDF',
            addComment: 'Añadir comentario',
            cancelPreinvoice: 'Cancelar Pre-Factura',
        },
        specialBlocks: {
            clientLabel: 'Cliente',
            projectLabel: 'Proyecto',
            futureBlocks: 'Bloques futuros',
        },
        invoice: {
            sinceDate: 'Fecha corte desde',
            untilDate: 'Fecha corte hasta',
            searchLabel: 'Buscar',
            settledLabel: 'Liquidadas',
            cancelBillTitle: 'Cancelar Factura',
        },
        billing: {
            state: {
                one: 'Aprobado',
                two: 'Anulado',
            },
        },
        creditNotes: {
            type: {
                full: 'Completa',
                partial: 'Parcial',
            },
            reason: {
                devolution:
                    'Devolución de parte de los bienes; no aceptación de partes del servicio',
                cancelBilling: 'Anulación de factura electrónica',
                discountTotalApplied: 'Rebaja total aplicada',
                dsctTotalApplied: 'Descuento total aplicado',
                resicionWithoutRequirements:
                    'Cancelación total por anulación de factura',
                others: 'Otros',
            },
            creationDateSinceLabel: 'Fecha creación desde',
            creationDateUntilLabel: 'Fecha creación hasta',
            clientLabel: 'Cliente',
            titleHeader: 'Notas crédito',
            create: 'Crear nota crédito',
            billLabel: 'Factura',
            originalBillLabel: 'Valor original de factura',
            cause: 'Razón',
            description: 'Descripción',
            noteType: 'Tipo',
            creditNoteValue: 'Valor nota crédito',
            maxCreditNoteValue: 'Valor máximo nota crédito',
            fieldRequired: 'Este campo es requerido',
            cannotCreateCreditNote:
                'No puedes realizar una nota crédito a esta factura, pues ya ha sido compensada en su totalidad',
            success: 'Nota crédito creada correctamente',
            mustSelectBill:
                'Debes seleccionar una factura para crear una nota de credito',
            fieldsRequired: 'Debes diligenciar todos los campos obligatorios',
            valuesShouldBeDifferent:
                'El valor de nota credito debe ser mayor a 0 y menor al valor del total de la factura',
            error: 'Ha ocurrido un error',
        },
        cashRegister: {
            creationDateSinceLabel: 'Fecha creación desde',
            creationDateUntilLabel: 'Fecha creación hasta',
            clientLabel: 'Cliente',
            searchLabel: 'Buscar',
            titleHeader: 'Recibos de Caja',
            createCashReceipt: 'Crear recibo de Caja',
            cashReceiptCurrencyText: 'Moneda de recibo de caja',
            billLabel: 'Factura',
            cashReceiptAmount: 'Monto del recibo de caja',
            description: 'Descripción',
            fieldRequired: 'Este campo es requerido',
            success: 'Recibo de caja creado correctamente',
            fieldsRequired: 'Debes diligenciar todos los campos obligatorios',
            cashReceiptValueGreaterThan:
                'El valor del recibo de caja debe ser mayor a cero',
            billAndTotalAmountShouldBeTheSame:
                'El valor de las facturas relacionas debe ser mayor a 0 e igual al total del valor del recibo de caja',
            error: 'Ha ocurrido un error',
        },
    },
    popUpComponent: {
        user: {
            titleEncargadoComercial: 'Encargado comercial',
            titleFidelizador: 'Fidelizador',
            subTitleEncargadoComercial:
                'Selecciona el encargado comercial para el proyecto.',
            subTitleFidelizador: 'Selecciona el fidelizador para el proyecto.',
        },
        clients: {
            title: 'Asociar clientes a contacto',
            subTitle:
                'Selecciona los clientes que quieres asociar o deselecciona para remover',
        },
        shareActivity: {
            Title: '¿A quién quieres enviar una copia?',
            Subtitle: 'Encargado comercial',
        },
        changeCurrency: {
            Title: 'Editar cambio de moneda',
            Subtitle: 'Tipos de cambio',
            TypeFacture: 'Fecha de consulta TRM',
        },
        activityActions: {
            delete: '¿Confirma que desea eliminar esta actividad de la presente prefactura?',
        },
        BillNotes: {
            btnShowHours: 'Ver horas registradas',
            Title: 'Notas de facturación',
            addNoteTitle: 'Agregar nota de facturación',
            reference:
                "Para mencionar a un usuario ingresa {'@'} y escribe el nombre.",
            labelDescription: 'Descripción de la nota',
            placeholderDescription: 'Escribe una breve nota',
            errorDescription: 'Debes escribir una nota',
            labelCash: 'Tipo de Cobro',
            placeholderCash: 'Selecciona un tipo de cobro',
            labelCurrency: 'Moneda',
            placeholderCurrency: 'Selecciona un tipo de moneda',
            labelValue: 'Valor',
            placehollder: 'Ingresa un valor a cobrar',
            cashHour: 'Por hora',
            cashFlat: 'Por dinero',
            state: {
                title: 'Estado',
                Created: 'Creado',
                Opened: 'Abierta',
                Closed: 'Cerrado',
            },
        },
        editProject: {
            title: 'Editar cliente y proyecto',
            labelClient: 'Cliente',
            placeholderClient: 'Selecciona un cliente',
            errorClient: 'Debes seleccionar un cliente',
            labelProject: 'Proyecto',
            placeholderProject: 'Selecciona un proyecto',
            errorProject: 'Debes seleccionar un proyecto',
        },
        typesString: {
            billdingNote: 'Facturación',
        },
        filterTemplate: {
            title: 'FILTRAR POR:',
            buttons: {
                cancel: 'Cancelar',
                delete: 'Borrar filtros',
                apply: 'Aplicar filtros',
                teams: 'Equipos o personas',
                prectice: 'Prácticas o Unidades de servicio',
                clients: 'Clientes',
            },
        },
        hourByActivity: {
            title: 'Registro de horas trabajadas en la actividad:',
        },
        reasignTemplate: {
            title: 'REASIGNAR A:',
            buttons: {
                cancel: 'Cancelar',
                delete: 'Borrar selección',
                apply: 'Guardar',
            },
        },
        areas: {
            title: 'EDITAR PRÁCTICAS Y UNIDADES DE SERVICIO PARA',
            titleRatebook: 'Prácticas y Unidades de servicio disponibles',
            complementTitle: 'TU NUEVO PROYECTO',
            subtitle:
                'Selecciona los campos que quieres agregar o deselecciona para remover.',
        },
        sessions: {
            title: 'Ya tienes una sesión activa en Lucy',
            body: '¿Deseas cerrar la sesión anterior y permanecer aquí?',
            buttons: {
                denySession: {
                    titleButton: 'Cancelar',
                },
                confirmSession: {
                    titleButton: 'Si, Permanecer aquí',
                },
            },
            closeSesion: {
                logo: 'logo-company.png',
                title: 'Sesión Cerrada',
                button: {
                    title: 'Iniciar sesión nuevamente',
                },
            },
        },
        outlook: {
            title: 'CREACIÓN DE CITACIONES OUTLOOK',
            labelAsistances: 'Asistentes',
            labelRecorded: 'Recordatorio en:',
            labelRecordedSecond: 'Recordatorio',
            labelfiles: 'Adjuntar archivos',
            labelsubjetc: 'Asunto',
            fileload: {
                name: 'Nombre',
                type: 'Tipo',
                size: 'Tamaño',
                actions: 'Acciones',
                megabytes: 'MB',
            },
        },
        preinvoiceComment: {
            header: 'Añadir comentario a prefactura',
            addComment: 'Añadir comentario',
            configSkip: 'Omitir prefactura',
            configSkipAll: 'Aprobar todas',
            configSaveOne: 'Guardar prefactura',
            configFinishPreinvoice: 'Guardar y aprobar',
            configCancelFinishPreinvoice: 'Cancelar',
            comenntAditionalInfo: 'Comentario',
            dynamicFormsTitle: 'Campos facturación electrónica',
        },
    },
    sideTabComponent: {
        client: {
            title: 'Detalle Cliente',
            name: 'Cliente: ',
            comercialName: 'Nombre Comercial: ',
            nit: 'NIT: ',
            direction: 'Dirección: ',
            phone: 'Telefono: ',
            contacts: 'Contactos: ',
        },
        project: {
            name: 'Nombre: ',
            area: 'Práctica: ',
            subArea: 'Unidad de Servicios: ',
            responsable: 'Encargado Comercial: ',
            workGroup: 'Grupo Fidelizador: ',
        },
        showCompleteInfo: 'Ver información completa',
        history: {
            emptyState: 'No se registran movimientos nuevos',
            property: 'Propiedad:',
            oldValue: 'Valor anterior:',
            newValue: 'Nuevo valor:',
        },
        reportActivities: {
            titleActivitiesSideTab: 'Actividades',
            titleFlowSideTab: 'Flujo',
            descriptionActivities:
                'Consulta todas las actividades relacionadas al flujo',
            descriptionFlow: 'Consulta el detalle del flujo',
        },
    },
    lateralHistoryComponent: {
        resultPut: {
            errorMessage: 'Ocurrió un error al actualizar la tarea',
            updateMessage: 'La tarea ha sido actualizada',
            finishMessage: 'La tarea ha sido finalizada',
        },
        noContentSection: 'No hay contenido disponible',
        tagsUsers:
            "Para mencionar a un usuario ingresa {'@'} y escribe el nombre.",
        editBtn: 'Editar',
        deleteBtn: 'Borrar',
        addContact: 'Agregar nuevo contacto',
        addContactSubTitle: 'Agrega un contacto que esté asociado con el cliente.',
        placeholderProfessional: 'Selecciona al profesional',
        textProfessional: 'Agregar el nombre del profesional',
        associateCompanyNames: 'Asociar razones sociales al contacto',
        typeMessageItems: {
            task: 'Tarea',
            comment: 'Comentario',
            modify: 'modificación',
        },
        titleOnTab:
            'Consulta los cambios, comentarios y procesos realizados hasta ahora',
        emptyHistoryChanges:
            'Todavía no se han realizado cambios en esta actividad',
        addActions: 'Agrega un mensaje a la bitácora de la actividad',
        includeUser:
            "Para mencionar a un usuario ingresa {'@'} y escribe el nombre",
        historyFileType: 'Archivo',
        historyTaskType: 'Tarea',
        historyCommentType: 'Comentario',
        modificationsIn: 'Modificaciones en',
        hasCreated: '- Ha creado',
        hasLoadedFile: '- Ha cargado el archivo ',
        hasDeletedFile: '- Ha borrado el archivo ',
        estimatedTime: 'Tiempo estimado:',
        billableTime: 'Tiempo facturable:',
        nonBillableTime: 'Tiempo no facturable:',
        hours: 'horas',
        create: 'creó',
        edit: 'edito',
        typeAction: 'Tipo de acción:',
        finishTask: 'Terminar tarea',
        cancel: 'Cancelar',
        save: 'Guardar',
        exit: 'Salir',
        goToActivity: 'Ir a la actividad',
        typeItem: {
            project: 'este proyecto',
            customer: 'este cliente',
            flowType: 'este tipo de flujo',
            activityType: 'este tipo de actividad',
            flow: 'este flujo',
            activity: 'este actividad',
            tradeAgreement: 'este acuerdo comercial',
            user: 'este usuario',
            workGroup: 'este grupo de trabajo',
            company: 'esta compañia',
            practice: 'esta práctica',
            rol: 'este rol',
            expense: 'este gasto',
            notExist: 'No existe',
        },
        state: {
            inProgress: 'En curso',
            slope: 'Pendiente',
            active: 'Activado',
            disabled: 'Desactivado',
            finish: 'Finalizada',
        },
        action: {
            labelMessage: 'Mensaje',
            placeholderMessage: 'Escribe el mensaje',
            errorMessage: 'Debes escribir un mensaje',
            labelTypeMessage: 'Tipo de mensaje',
            placeholderTypeMessage: 'Selecciona el tipo de mensaje',
            errorTypeMessage: 'Debes seleccionar un tipo un mensaje',
            formats: 'docx, xlsx, pdf, mp4, awv hasta 5MB',
            messageCreate: 'La tarea ha sido creada',
            messageErrorActivity: 'Debes crear una actividad primero',
            messageError: 'Debes crear una tarea primero',
            commentMessageCreate: 'El comentario ha sido creado',
            commentMessageError: 'Debes crear el comentario primero',
            messageUpdate: 'Se ha actualizado ',
        },
        styleLine: {
            typeHoursAdd:
                '<span class="newValueTime">Agregó </span> <span class="value">',
            typeHoursDel:
                '<span class="oldValueTime"> Descontó </span> <span class="value">',
            typeMinutesAdd:
                '<span class="newValueTime">Agregó </span> <span class="value">',
            typeMinutesDel:
                '<span class="oldValueTime"> Descontó </span><span class="value">',
            connectEndLine: '</span> al tiempo trabajado de la actividad.',
            reOpenActivity: 'Ha reabierto esta actividad.',
            downloadActivity: 'Ha descargado esta actividad.',
            changeField: '- Cambió el campo <span class="value">',
            practiceAsociatedField:
                ' Asociado a la práctica <span class="value">',
            oldValueChange: '</span> del valor: <span class="oldValue">',
            dayOldValueChange: '</span> del día: <span class="oldValue">',
            dayNewValueChange: '</span> a estado: <span class="newValue">',
            newValueChange: '</span> al nuevo valor: <span class="newValue">',
            asignedData: '- Asignó el valor: <span class="newValue">',
            asignedDataField: '</span> al campo <span class="value">',
            closeSpan: '</span>',
            shareActivity:
                'Se ha compartido la actividad con <span class="value">',
            changeFieldAgregate: '- Agregó al campo <span class="value">',
            changeFieldDelete: '- Eliminó en el campo <span class="value">',
            fieldNameResponsable: '</span> al usuario <span class="oldValue">',
            changeFieldCurrency: '</span> De la moneda <span class="value">',
            changedFiedlEquivalences: '- Agregó al campo <span class="value">',
            changedfieldEquivalencesValue:
                '</span> un nuevo registro con Valor: <span class="newValue">',
            changedFiedlEquivalencesDate:
                '</span> Y Fecha: <span class="newValue">',
            changeFieldEquivalencesSingle:
                '- Modificó el campo <span class="value">',
            changeFieldEquivalencesSingleDate:
                '</span> en uno de lo registros de equivalencias con Fecha: <span class="oldValue">',
            changeFieldEquivalencesSinglenewDate:
                '</span> a la nueva Fecha: <span class="newValue">',
            changeFieldEquivalencesSingleValue:
                '</span> en uno de lo registros de equivalencias con Valor: <span class="oldValue">',
            changedFiedlEquivalencesSinglenewValue:
                '</span> al nuevo Valor: <span class="newValue">',
            changedFiedlEquivalencesDeleteSingleValue:
                '- Eliminó un registro del campo Equivalencias con Valor: <span class="oldValue">',
            changedfieldEquivalencesDeleteSingleDate:
                '</span> y Fecha: <span class="oldValue">',
            changedFieldRoleAdd:
                'se Agregó al rol el permiso <span class="newValue">',
            changedFieldRoleDelete:
                'se eliminó del rol el permiso <span class="deleteValue">',
            changedFieldAddUserCreateInit:
                'se ha agregado al proyecto el encargado comercial <span class="newValue">',
            changedFieldAddUserCreatedEnd:
                '</span> al campo <span class="value">',
            changedFieldAddGroupWorkCreateInit:
                'se ha agregado al proyecto el grupo de trabajo <span class="newValue">',
            changedFieldAddGroupWorkCreatedEnd:
                '</span> al campo <span class="value">',
            changedFieldDeleteUserCreateInit:
                'se ha retirado del proyecto el usuario <span class="deleteValue">',
            changedFieldDeleteUserCreatedEnd:
                '</span> del campo <span class="value">',
            changedFieldAddUserExternCreateInit:
                'se ha agregado al proyecto el usuario externo <span class="newValue">',
            changedFieldDeleteUserSolicitedContact:
                '- Eliminó al usuario <span class="deleteValue">',
            changedFieldUserSolicitedContact:
                '</span> del campo <span class="value">',
            changeFieldPracticesUnitServiceAdd:
                '</span> la práctica <span class="newValue">',
            changeFieldPracticesUnitServiceDelete:
                '</span> la práctica <span class="deleteValue">',
        },
        dynamicForm: {
            dataTypeField: {
                Title: 'Tipo de dato',
                Number: 'Numérico',
                RadioGroup: 'Selección Individual',
                Select: 'Selección Multiple',
                Date: 'Fecha',
                Textarea: 'Párrafo',
                Text: 'Línea de texto',
                Checkbox: 'Selección',
            },
            InfoWorkflows: {
                Title: 'Tipos de flujo',
            },
            Description: 'Descripción',
            Empty: 'Vacío',
            Name: 'Nombre',
        },
        profileTax: {
            Empty: 'Vacío',
            Name: 'Nombre',
            Description: 'Descripción',
            Active: {
                Title: 'Estado',
                False: 'Inactivo',
                True: 'Activo',
            },
            Value: 'Valor(%)',
            TaxType: {
                Title: 'Tipo de Impuesto',
                Tax: 'Impuesto',
                WithHolding: 'Retención',
            },
            Taxes: 'Inpuestos',
        },
    },
    lateralHistoryCitationComponent: {
        sidetab: {
            title: 'DETALLES CITACIONES OUTLOOK',
            Undefined: 'No definido',
        },
        main: {
            titleMain: 'Historial de cambios',
            titleHistory: 'Consulta los cambios de las citaciones hasta ahora',
            emptyResultsCitations:
                'Todavía no han realizado citaciones en esta actividad',
        },
        citation: {
            titleFiles: 'Archivos Adjuntos: ',
            titleHistory: 'Envió una citación con los siguientes detalles',
            emailOf: 'De:',
            emailTo: 'Para:',
            emailSubject: 'Asunto:',
            emailBody: 'Cuerpo del mensaje:',
            emailFiles: {
                name: 'Nombre',
                type: 'Tipo',
                actions: 'Acciones',
                emptyMessage: 'No hay adjuntos',
            },
        },
    },
    customPopup: {
        titleExitForm: '¿Seguro quieres salir?',
        subTitleExitForm:
            'Ten en cuenta que realizaste alguna modificación en el formulario y no has guardado.',
        subTitleDynamicForm:
            'Ten en cuenta que faltan algunos campos por diligenciar para que el flujo quede correctamente guardado, al no completarlos aceptas la eliminación del mismo.',
        titleIpdateApp: 'Lucy necesita actualizarse',
        subTitleUpdateApp:
            'Hay una nueva versión de la aplicación y debe instalarse en en este momento.',
        saveTitleUpdate: 'Aceptar',
        continueTitleUpdate: 'Continuar',
        settlementLayout: {
            sendActivitiesToPreinvoice: {
                title: 'Confirmación de envío de actividades a prefactura',
                subtitle:
                    '¿Estas seguro que deseas enviar las actividades seleccionadas al estado Prefactura?',
            },
            sendActivitiesToPreinvoiceInter: {
                title: 'Confirmación de envío de actividades a prefactura',
                subtitle:
                    '¿Estas seguro que deseas enviar las actividades seleccionadas al estado Prefactura?',
            },
            sendBlocks: {
                title: 'Confirmacion creacion de prefactura',
                subtitle:
                    '¿Esta seguro que desea enviar estos datos para crear una sola prefactura?',
            },
            sendBlockSeparate: {
                title: 'Confirmacion creacion de prefacturas',
                subtitle:
                    '¿Esta seguro que desea enviar estos datos para crear prefacturas distintas?',
            },
            sendBlocksInter: {
                title: 'Confirmacion creacion de prefactura',
                subtitle:
                    '¿Esta seguro que desea enviar estos datos para crear una sola prefactura?',
            },
            sendBlockSeparateInter: {
                title: 'Confirmacion creacion de prefacturas',
                subtitle:
                    '¿Esta seguro que desea enviar estos datos para crear prefacturas distintas?',
            },
            shutDownBlocksOnly: {
                title: 'Confirmación de cierre de bloque de liquidación',
                subtitle:
                    '¿Esta seguro que desea cerrar el bloque de liquidacion seleccionado?',
            },
            shutDownBlocks: {
                title: 'Confirmación de cierre de bloques de liquidación',
                subtitle:
                    '¿Estas seguro que deseas cerrar el bloque de liquidación seleccionado?',
            },
            aprovePreinvoiceOnly: {
                title: 'Confirmacion de aprobacion de prefactura',
                subtitle:
                    '¿Esta seguro que desea aprobar las prefactura seleccionadas?',
            },
            aprovePreinvoice: {
                title: 'Confirmacion de aprobacion de prefacturas',
                subtitle:
                    '¿Esta seguro que desea aprobar las prefacturas seleccionadas?',
            },
            cancelPreinvoice: {
                title: 'Solicitud de cancelacion de prefactura',
                subtitle:
                    '¿Esta seguro que desea cancelar la prefactura seleccionada?',
            },
        },
        modalDeactivateProyect: {
            question: '¿Seguro que quieres desactivar este proyecto?',
            subtitle: 'Este proyecto puede tener asignado',
            listItems: {
                tradeAgreement: 'Acuerdos Comerciales',
                tradeAgreementBlock: 'Bloques de acuerdos comerciales',
                workFlows: 'Flujos',
                Activity: 'Actividades',
                Tasks: 'Tareas',
            },
        },
    },
    popUpWorksComponent: {
        labelEmptyState: 'No tienes tareas asignadas todavía',
        action: {
            messageUpdate: 'Se ha terminado/actualizado la tarea',
            messageError: 'Ocurrió un error al actualizar la tarea',
        },
    },
    documentationCenter: {
        title: 'Centro de documentación',
        subtitle:
            'Encuentra y accede a los archivos cargados previamente en actividades, flujos, proyectos, clientes y documentos guía de la firma. También puedes conectarte a un gestor documental externo.',
        form: {
            labelFolder: 'Carpetas',
            placeholderFolder: 'Selecciona una carpeta donde buscar.',
            initDateLabel: 'Fecha de carga superior a',
            initDatePlaceholder: 'Selecciona la fecha de inicio del rango',
            finDateLabel: 'Fecha de carga inferior a',
            finDatePlaceholder: 'Selecciona la fecha finalización del rango',
            wordSearchLabel: 'Palabras clave  en el titulo',
            wordSearchPlaceholder:
                'Agrega una palabra y presiona enter para buscar en el titulo',
            btnSearch: 'Consultar Archivos',
            titleHeader: 'Archivos',
            option1: 'Lucy',
            option2: 'SIAC',
            uniqueCode: 'Codigo unico',
            uniqueCodePlaceholder: 'Digita el codigo unico',
        },
    },
    customDropdown: {
        teamLabel: 'Equipos',
        teamPlaceolder: 'Selecciona el equipo',
        userLabel: 'Equipo o persona',
        userPlaceolder: 'Selecciona el equipo o la persona',
        areasLabel: 'Práctica, Unidades de servicio',
        areasPlaceolder: 'Selecciona práctica, unidades de servicio',
        clientesLabel: 'Cliente',
        clientesPlaceolder: 'Selecciona el cliente',
        buttonSave: 'Guardar',
        buttonCancel: 'Cancelar',
        apoyoLabel: 'Usuario de apoyo',
        apoyoPlaceolder: 'Selecciona los usuarios de apoyo',
    },
    reports: {
        labelLoader: 'Descargando...',
    },
    printablePreInvoice: {
        nit: 'NIT:',
        address: 'Dirección:',
        phone: 'Teléfono:',
        email: 'Email de contacto:',
        description: 'Descripción:',
        value: 'Valor:',
        discount: 'Descuento:',
        total: 'Total:',
        totalBilling: 'Total a pagar:',
        subtotal: 'Subtotal:',
        selectedTaxes: 'Impuestos seleccionados:',
        valueTaxes: 'Valor impuesto:',
        projects: 'Proyectos',
        endDate: 'Fecha',
        project: 'Proyecto',
        totalGrid: 'Total',
        professionals: 'Profesionales',
        professionalsName: 'Nombre',
        valueprofessionals: 'Valor',
        abbreviatedName: 'Abreviatura',
        professionalsTime: 'Tiempo / Porcentaje',
        activities: 'Actividades',
        activityTitle: 'Titulo actividad',
        activityType: 'Tipo de actividad',
        activityDescription: 'Descripción',
        activityFlow: 'Flujo',
        activityResponsable: 'Abogado',
        activityBillable: 'Facturable',
        activityValue: 'Valor',
        activityTime: 'Tiempo',
        infoAdicional: 'Información adicional',
    },
    clientDashboard: {
        clientTitle: 'Clientes',
        clientInfo: 'Información del cliente',
        clientContacts: 'Contactos',
        clientFields: 'Campos personalizados',
        clientName: 'Nombre:',
        clientTypeIdentification: 'Tipo de identificación:',
        clientUniqueIdentificationNumber: 'Identificación:',
        clientAddress: 'Dirección:',
        clientBusinessName: 'Nombre comercial:',
        clientEmail: 'Email:',
        clientPhone: 'Teléfono:',
        clientBondingDate: 'Fecha de unión:',
        clientIsNaturalPerson: '¿Es persona natural?',
        clientCompanyName: 'Compañía asociada:',
        clientLoyaltyName: 'Fidelizador:',
        clientEnterpriseGroupName: 'Grupo de trabajo:',
        clientPostalCode: 'Código postal:',
        clientCode: 'Código:',
        clientCountry: 'País:',
        clientActive: 'Activo:',
    },
};
