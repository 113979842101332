export default () => ({
    title: '',
    subTitle: '',
    params: '',
    workFlowsCreateOrEditObjectProd: {
        id: '',
        companyId: '',
        name: '',
        areaId: '',
        areaName: '',
        subAreaName: '',
        subAreaId: '',
        code: '',
        dynamicForm: '',
        description: '',
        activities: [{}],
        definition: '',
        beginTime: '',
        active: '',
    },
    configFilters: {
        date: true,
        users: true,
        practice: true,
        client: true,
        openAndClose: false,
    },
    jobProduction: {},
    OperativeActivityValues: {},
    operativeDinamycValues: {},
    haveResponsable: true,
    listDefendants: [],
    listPlaintiffs: [],
    currentCase: {},
    notificationsByUser: [],
    viewPopUpNotifications: false,
    guidBillCreated: null,
    operativeWorkflow: {
        id: null,
        companyId: null,
        companyName: null,
        clientId: null,
        clientName: null,
        proyectId: null,
        proyectName: null,
        state: 1,
        code: null,
        name: null,
        type: null,
        area: null,
        subArea: null,
        userResponsable: null,
        groupResponsable: null,
        descripcion: null,
        tradeAgreementId: null,
        operativeDynamicForm: null,
        definition: null,
        activities: [],
        attachements: [],
        fields: [],
        readyDiagram: false,
    },
    operativeActivitie: {
        id: null,
        workGroupUserId: null,
        tradeAgreementId: null,
        userId: null,
        workFlowId: null,
        proyectName: null,
        proyectId: null,
        username: null,
        clientId: null,
        clientName: null,
        activityId: null,
        subAreaId: null,
        startDate: null,
        endDate: null,
        deliveryDate: null,
        registrationDate: null,
        tradeAgreementName: null,
        activityName: null,
        businessName: null,
        userCategory: null,
        name: null,
        totalTaskHours: null,
        processId: null,
        isBillable: false,
        estimatedHours: null,
        billiableHours: null,
        gap: 0,
        shareUsers: null,
        requiredDocuments: null,
        awaitingRevisionState: 1,
        requiredRevision: null,
        oportunityState: 1,
        state: 1,
        isAppointmentSchedule: null,
        isAutoassignable: null,
        fields: [],
        hours: [],
        files: [],
        typeNotification: [],
        subtopicId: null,
        subtopicName: null,
        tasks: null,
        firstEndHour: null,
        lastEndHour: null,
        description: null,
        assignedDate: null,
    },
    tradeAgreementProduction: {
        type: 0,
        info: null,
    },
    tradeAgreementProductionObject: null,
    operativeActivitiesToCreate: 0,
    operativeActivitiesCreatedArray: [],
    idOperativeWorkflowToProcess: '00000000-0000-0000-0000-000000000000',
    idTradeAgreementOptWf: null,
    idDynamicFormOptWf: null,
    operativeDinamycForm: null,
    operativeDinamycFormWithAnswers: null,
    expenseFormState: {},
    idActivityToShowOnSidetab: null,
    workflowToSidetab: {},
    showShareTabOnActivity: true,
    disabledCheckTradeAgreement: false,
    paramsWorkflow: JSON.parse(sessionStorage.getItem('m_paramsWF')),
    workFlowIsClose: false,
    workFlowInProgress: false,
    updateActivityByTask: false,
    localStateOportunity: 0,
    infoLitigiosData: null,
    showItems: true,
    subAreasByProjects: [],
    selectedUserResponsableWorkflow: null,
    activitySaved: 0,
    eliminatedFlow: false,
    habilityPopupState: false,
    gridReport: null,
    titleReport: 'Reportes de formularios dinámicos',
    showReopen: false,
    isCyclicActivity: false,
    isCyclicActivityRepeat: null,
    activitiesForFlow: [],
    restartDynamicFormComponent: false,
    isActivityComingFromFlow: false,
    isEndDateTradeAgreementValidation: false,
    partnerGrid: null,
    fromDashClients: false,
    isEndDateTradeAgreementValidationNumber: 0,
    isHabilityBtnHistoryCitation: false,
});
