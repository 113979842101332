export default {
    name: 'help',
    component: () =>
        import(
            /* webpackChunkName: "help" */ '@/modules/help/layouts/HelpLayout.vue'
        ),
    children: [],
    beforeEnter: (to, from, next) => {
        //console.log(`${from.path} to ${to.path}?`);
        next(false);
    },
};
