export const updateStateMenu = (state) => {
    state.stateMenu = !state.stateMenu;
};

export const setParams = (state, params) => {
    sessionStorage.setItem('m_params', JSON.stringify(params));
    state.params = params;
};

export const updateActiveMenu = (state, activeMenu) => {
    state.activeMenu = activeMenu;
};

export const updateActiveModule = (state, activeModule) => {
    state.activeModule = activeModule;
};

export const setTradeAgreementEntitie = (state, dataTrade) => {
    state.tradeAgreementEntitie = dataTrade;
};

export const setNotificationsByTradeAgreement = (state, dataTrade) => {
    state.notificationsTrade = dataTrade;
};

export const updateActiveSubModule = (state, activeSubModule) => {
    state.activeSubModule = activeSubModule;
};

export const setActivities = (state, activities) => {
    state.activities = activities;
};

export const setCompanies = (state, companies) => {
    state.companies = companies;
};

export const setCategories = (state, categories) => {
    state.categories = categories;
};

export const setGrid = (state, grid) => {
    state.grid = grid;
};
export const setGrid2 = (state, grid2) => {
    state.grid2 = grid2;
};

export const setAreas = (state, areas) => {
    state.areas = areas;
};

export const setWorkflows = (state, flows) => {
    state.flows = flows;
};

export const setUsers = (state, users) => {
    state.users = users;
};

export const setWorkGroup = (state, workGroups) => {
    state.workGroups = workGroups;
};

export const setRoles = (state, roles) => {
    state.roles = roles;
};

export const setSubthemes = (state, subthemesByActivity) => {
    state.subthemesByActivity = subthemesByActivity;
};
export const setSubthemeList = (state, subthemesByActivity) => {
    state.subthemeListValues = subthemesByActivity;
};

export const setUsersShareList = (state, usersShareList) => {
    state.usersShareList = usersShareList;
};

export const setContacts = (state, contacts) => {
    state.contacts = contacts;
};
export const setContactForm = (state, contact) => {
    state.contactsInfoState = contact;
};
export const setContactsByClient = (state, contactsByClient) => {
    state.contactsByClient = [...contactsByClient];
};
export const setClientsByContact = (state, clientsByContact) => {
    state.clientsByContact = clientsByContact;
};

export const updateActivities = (state, activity) => {
    state.activities = [...state.activities, activity];
};

export const updateActivity = (state, activity) => {
    // console.log.log('activity', activity);
    const newActivity = state.activities.find((activity) => {
        // console.log.log('activity', activity);
    });
    // console.log.log('newActivity', newActivity);
    // state.activities = [...state.activities, newActivity];
};

export const setCustomerForm = (state, customer) => {
    state.customerFormState = customer;
};
export const setCustomers = (state, customers) => {
    state.customers = customers;
};

export const setProjects = (state, projects) => {
    state.projects = projects;
};

export const setFlows = (state, flows) => {
    state.flows = flows;
};

export const setAlerts = (state, alerts) => {
    state.alerts = alerts;
};

export const setDynamicForms = (state, dynamicForms) => {
    state.dynamicForms = dynamicForms;
};

export const setUserForm = (state, userForm) => {
    state.userFormState = userForm;
};

export const setSelectAreasByUser = (state, areasByUser) => {
    state.areasByUser = areasByUser;
};
export const setSelectAreasSelected = (state, areasByUser) => {
    state.areasByUserSelected = areasByUser;
};

export const setEnterpriseGroups = (state, enterpriseGroups) => {
    state.enterpriseGroups = enterpriseGroups;
};

export const setTradeAgreements = (state, tradeAgreements) => {
    state.tradeAgreements = tradeAgreements;
};

export const setBills = (state, bills) => {
    state.bills = bills;
};

export const setTradeAgreementsForm = (state, tradeAgreementsForm) => {
    state.tradeAgreementsForm = tradeAgreementsForm;
    // console.log.log(state.tradeAgreementsForm, 'state.tradeAgreementsForm');
};

export const setTradeAgreementIdForView = (state, tradeAgreementId) => {
    state.tradeAgreementIdForView = tradeAgreementId;
};

export const setProjectIdForView = (state, projectId) => {
    state.projectIdForView = projectId;
};

export const setProjectActivities = (state, activities) => {
    state.projectActivities = activities;
};
export const setWorkFlowsCreateOrEditObjectDefinitionProperty = (
    state,
    WorkFlowsCreateOrEditObjectDefinitionProperty,
) => {
    state.workFlowsCreateOrEditObject.definition =
        WorkFlowsCreateOrEditObjectDefinitionProperty;
};
export const setActvititesTypeByAreaIdAndSubAreaIdAndState = (
    state,
    actvititesTypeByAreaIdAndSubAreaIdAndState,
) => {
    state.actvititesTypeByAreaIdAndSubAreaIdAndState =
        actvititesTypeByAreaIdAndSubAreaIdAndState;
};
export const setActionTypeWorkflows = (state, actionTypeWorkflows) => {
    state.actionTypeWorkflows = actionTypeWorkflows;
};
export const setWorkFlowsCreateOrEditObjectActivitiesProperty = (
    state,
    activities,
) => {
    state.workFlowsCreateOrEditObject.activities = activities;
};
export const setGeneralInfotWorkFlowsCreateOrEditObject = (state, data) => {
    state.workFlowsCreateOrEditObject.id = data.id;
    state.workFlowsCreateOrEditObject.code = data.code;
    state.workFlowsCreateOrEditObject.companyId = data.companyId;
    state.workFlowsCreateOrEditObject.name = data.name;
    state.workFlowsCreateOrEditObject.areaId = data.areaId;
    state.workFlowsCreateOrEditObject.areaName = data.areaName;
    state.workFlowsCreateOrEditObject.subAreaName = data.subAreaName;
    state.workFlowsCreateOrEditObject.subAreaId = data.subAreaId;
    state.workFlowsCreateOrEditObject.dynamicForm = data.dynamicForm;
    state.workFlowsCreateOrEditObject.description = data.description;
    state.workFlowsCreateOrEditObject.dynamiceFormName = data.dynamiceFormName;
    state.workFlowsCreateOrEditObject.active = data.active;
    state.workFlowsCreateOrEditObject.beginTime = data.beginTime;
};
export const setGeneralInfotWorkFlowsCreateOrEditObjectId = (state, data) => {
    state.workFlowsCreateOrEditObject.id = data;
};
export const setNotificationsFlow = (state, data) => {
    if (data.notifications === null) {
        state.notificationsFlowData = [];
    } else {
        data?.notifications?.map((n) => {
            n.typeSendTitle =
                n.typeSend[0] == 1
                    ? 'Email'
                    : n.typeSend[0] == 2
                    ? 'SMS'
                    : n.typeSend[0] === 3
                    ? 'Push'
                    : n.typeSend[0] === 4
                    ? 'WhatsApp'
                    : '';
            n.dateEvaluateTitle =
                n.dateEvaluate == 1
                    ? 'Fecha de inicio'
                    : n.dateEvaluate == 2
                    ? 'Fecha de entrega'
                    : n.dateEvaluate == 3
                    ? 'fecha de finalización'
                    : '';
            n.typeNotificationTitle =
                n.typeNotification == 1
                    ? 'Faltan X días para la fecha de entrega de la actividad X del flujo.'
                    : n.typeNotification == 2
                    ? 'Han pasado X días de la fecha X de la actividad X del flujo.'
                    : n.typeNotification == 3
                    ? 'Hoy se cumple la fecha de entrega de la actividad X del flujo.'
                    : '';
            return n;
        });
        state.notificationsFlowData = data.notifications;
    }
};
export const setTotalObjectWorkFlow = (state, data) => {
    state.workFlowObject = data;
};

export const setActionToExecuteAfterClosePopUpDiagram = (
    state,
    actionToExecuteAfterClosePopUpDiagram,
) => {
    state.actionToExecuteAfterClosePopUpDiagram =
        actionToExecuteAfterClosePopUpDiagram;
};

export const setDynamicFormsToWorkflows = (state, dynamicFormsToWorkflows) => {
    state.dynamicFormsToWorkflows = dynamicFormsToWorkflows;
};

export const setActionTypeOrgChart = (state, actionTypeOrgChart) => {
    state.actionTypeOrgChart = actionTypeOrgChart;
};

export const setUsersToOrgChart = (state, usersToOrgChart) => {
    state.usersToOrgChart = usersToOrgChart;
};

export const setOrgChartCreateOrEditObject = (
    state,
    orgChartCreateOrEditObject,
) => {
    state.orgChartCreateOrEditObject.id = orgChartCreateOrEditObject.id;
    state.orgChartCreateOrEditObject.idCompany =
        orgChartCreateOrEditObject.idCompany;
    state.orgChartCreateOrEditObject.IdCompany =
        orgChartCreateOrEditObject.idCompany;
    (state.orgChartCreateOrEditObject.name = orgChartCreateOrEditObject.name),
        (state.orgChartCreateOrEditObject.description =
            orgChartCreateOrEditObject.description);
    state.orgChartCreateOrEditObject.code = orgChartCreateOrEditObject.code;
    state.orgChartCreateOrEditObject.externalUsers =
        orgChartCreateOrEditObject.externalUsers;
};

export const setOrgChartCreateOrEditObjectMembersProperty = (
    state,
    members,
) => {
    state.orgChartCreateOrEditObject.members = members;
};

export const setOrgChartCreateOrEditObjectDefinitionProperty = (
    state,
    definition,
) => {
    state.orgChartCreateOrEditObject.definition = definition;
};

export const setActionToExecuteAfterChargeOrgChart = (
    state,
    actionToExecuteAfterChargeOrgChart,
) => {
    state.actionToExecuteAfterChargeOrgChart =
        actionToExecuteAfterChargeOrgChart;
};

export const setDynamicFieldsByForm = (state, dynamicFieldsByForm) => {
    state.dynamicFieldsByForm = dynamicFieldsByForm;
};

export const setDynamicForm = (state, dynamicForm) => {
    state.dynamicForm = dynamicForm;
    state.dynamicForm.id = dynamicForm.id ?? '';
    state.dynamicForm.companyId = dynamicForm.companyId ?? '';
    state.dynamicForm.code = dynamicForm.code ?? '';
    state.dynamicForm.name = dynamicForm.name ?? '';
    state.dynamicForm.description = dynamicForm.description ?? '';
    state.dynamicForm.infoWorkflows = dynamicForm.infoWorkflows;
    state.dynamicForm.fields = dynamicForm.fields ?? '';
};

export const setTypeExpenses = (state, typeExpenses) => {
    state.typeExpenses = typeExpenses;
};
export const setCompanyActiveId = (state, id) => {
    state.companyActiveId = id;
};

export const setInfoWorkflowsByDynamicForm = (state, workflows) => {
    state.dynamicForm = { ...state.dynamicForm, infoWorkflows: workflows };
};

export const setAreaById = (state, area) => {
    state.area = area;
};

export const setProjectsWithNameClient = (state, projectsWithNameClient) => {
    state.projectsWithNameClient = projectsWithNameClient;
};

export const setActivitySubAreaByProject = (
    state,
    activitySubAreaByProject,
) => {
    state.activitySubAreaByProject = activitySubAreaByProject;
};
export const setPossibleUsersAndTime = (state, possibleUsersAndTimeValues) => {
    state.possibleUsersAndTimeValues = possibleUsersAndTimeValues;
};

export const setInfoUserOff = (state, infoUser) => {
    state.infoUserOff = infoUser;
};

export const setUsersReasign = (state, usersReasign) => {
    state.usersReasign = usersReasign;
};

export const setJobManagement = (state, userJob) => {
    state.jobManagement = userJob;
};
export const setSaveReasign = (state, stateReasign) => {
    state.saveReasign = stateReasign;
};

export const setTypeActivity = (state, typeActivity) => {
    state.typeActivity = typeActivity;
};

export const setWorkflowsWithAresaAndSubAreas = (
    state,
    workflowsWithAresaAndSubAreas,
) => {
    state.workflowsWithAresaAndSubAreas = workflowsWithAresaAndSubAreas;
};

export const setFieldsOfCustomItemComponent = (
    state,
    fieldsOfCustomItemComponent,
) => {
    state.fieldsOfCustomItemComponent = fieldsOfCustomItemComponent.sort(
        (a, b) => a.order - b.order,
    );
    sessionStorage.setItem(
        'storedFields',
        JSON.stringify(fieldsOfCustomItemComponent),
    );
};

export const setCustomPopupOnClose = (state, newValue) => {
    state.customPopupOnClose = newValue;
};

export const setSelectedPreinvoices = (state, newValue) => {
    state.selectedPreinvoices = newValue;
};

export const setWorkGroupsForDropdown = (state, workGroupsForDropdown) => {
    state.workGroupsForDropdown = workGroupsForDropdown;
};

export const setUsersByWorkGroupsForDropdown = (
    state,
    usersByWorkGroupsForDropdown,
) => {
    state.usersByWorkGroupsForDropdown = usersByWorkGroupsForDropdown;
};

export const setUpdateTypeCompany = (state, updateTypeCompany) => {
    state.updateTypeCompany = updateTypeCompany;
};

export const setUpdateStateLoading = (state, updateStateLoading) => {
    state.isLoading = updateStateLoading;
};

export const resultPut = (state, response) => {
    state.resultPut = response;
};

export const setProcessWithNameAndIdForDropdown = (
    state,
    processWithNameAndIdForDropdown,
) => {
    state.processWithNameAndIdForDropdown = processWithNameAndIdForDropdown;
};

export const setDynamicFormsValue = (state, dynamicFormsValue) => {
    state.dynamicFormsValue = dynamicFormsValue;
};

export const setCustomFiledsValue = (state, dynamicFormsValue) => {
    state.customFieldsValue = dynamicFormsValue;
};

export const setDataToRenderizeIntoDynamicForm = (
    state,
    dataToRenderizeIntoDynamicForm,
) => {
    state.dataToRenderizeIntoDynamicForm = dataToRenderizeIntoDynamicForm;
};

export const setWorkGroupsByUser = (state, workGroupsByUser) => {
    state.workGroupsByUser = workGroupsByUser;
};

export const setPermissionsByModule = (state, permissionsByModule) => {
    state.allPermissions = permissionsByModule;
};

export const setFullAreasUsuario = (state, areas) => {
    state.fullAreasUsuario = areas;
};

export const setRolesForm = (state, rolesForm) => {
    state.rolesForm = rolesForm;
};

export const setProjectForm = (state, projectForm) => {
    state.projectForm = { ...state.projectForm, ...projectForm };
};

export const setTradeAgreementId = (state, id) => {
    state.tradeAgreementsIds = [...state.tradeAgreementsIds, id];
};

export const setSeletedCompany = (state, company) => {
    // console.log('data--->>>', company);
    state.seletedCompany = company;
};

export const setSeletedPrincipalCompany = (state, Principalcompany) => {
    state.mainPrincipalCompanyPopUp = Principalcompany;
};

export const setHistoryById = (state, history) => {
    state.historialById = history;
};

export const setTypenotifications = (state, notifications) => {
    state.typeNotifications = notifications;
};

export const setContactByClient = (state, contacts) => {
    state.contactByClient = contacts;
};

export const setIdProyect = (state, id) => {
    state.proyectActiveId = id;
};

export const setNameProyect = (state, name) => {
    state.proyectActiveName = name;
};

export const setUserMentions = (state, users) => {
    state.usersMentions = users;
};

export const setParamsTradeAgreement = (state, params) => {
    sessionStorage.setItem('m_paramsTradeAgreement', JSON.stringify(params));
    state.paramsTradeAgreement = params;
};

export const setTradeAgreementForm = (state, data) => {
    state.tradeAgreementFrom = data;
};

export const setDatesTradeAgreement = (state, data) => {
    state.datesTradeAgreement = data;
};

export const setParamsBills = (state, params) => {
    sessionStorage.setItem('m_paramsBills', JSON.stringify(params));
    state.paramsBills = params;
};

export const setProjectFormState = (state, projectForm) => {
    state.projectForm = projectForm;
};

export const updateActiveStepModule = (state, activeStepModule) => {
    state.activeStepModule = activeStepModule;
};
export const ShowpopupWorkgroups = (state, statePopup) => {
    state.ShowpopupWG = statePopup;
};

export const setSubthemeCreate = (state, subthemeNew) => {
    state.subthemeNew = subthemeNew;
};

export const setSubthemeUpdate = (state, subthemeUpd) => {
    state.subthemeUpd = subthemeUpd;
};

export const setSubthemeDelete = (state, subthemeDel) => {
    state.subthemeDel = subthemeDel;
};
export const SetHitosActivitiesOption = (state, options) => {
    state.listHitosActivities = options;
};
export const getcurrenciesOption = (state, options) => {
    state.currencies = options;
};
export const GetCurrency = (state, options) => {
    state.currency = options;
};
export const Getindexingunit = (state, options) => {
    state.Indexingunit = options;
};
export const setcurrencyandindexingunit = (state, options) => {};
export const Getindexingbyid = (state, options) => {
    state.objindexingbyid = options;
};
export const setSystemForm = (state, systemForm) => {
    state.systemFormState = systemForm;
};

export const setConfigCompanyById = (state, infoCompany) => {
    state.infoCompanyById = infoCompany;
};
export const getPracticesAll = (state, options) => {
    state.practicesObjListAll = options;
};
export const getPractices = (state, options) => {
    state.practicesObjList = options;
};
export const getPracticesbyid = (state, options) => {
    state.objpracticesbyid = options;
};
export const setGeneralInfotWorkFlowsCreateOrEditObjectBeginTimeProperty = (
    state,
    data,
) => {
    state.workFlowsCreateOrEditObject.beginTime = data;
};

export const setCompanyActiveName = (state, name) => {
    state.companyActiveName = name;
};

export const setUsersAdded = (state, usersAdded) => {
    state.usersAdded = usersAdded;
};

export const setUsersRemoved = (state, usersRemoved) => {
    state.usersRemoved = usersRemoved;
};
export const getsubareas = (state, options) => {
    state.subareaslist = options;
};
export const getallareas = (state, options) => {
    state.areaslist = options;
};
export const getareabyid = (state, options) => {
    state.areabyid = options;
};
export const updateActiveLevel = (state, activeLevel) => {
    state.activeLevel = activeLevel;
};
export const setfilesgeneratebyaws = (state, options) => {
    state.filesgeneratebyaws = options;
};
export const getfilesloaded = (state, options) => {
    state.listFilesloaded = options;
};
export const setFilesS3Added = (state, objfiles) => {
    state.objfilesuploadS3 = objfiles;
};

export const setAuxHistory = (state, cont) => {
    state.auxHistory = cont;
};
export const setIdHistoryItem = (state, cont) => {
    state.idHistoryItem = cont;
};

export const setlistadFilesXcomponente = (state, listfiles) => {
    state.listadFilesXcomponente = listfiles;
};
export const Mtispermittedfile = (state, ispermited) => {
    state.ispermittedfile = ispermited;
};

export const setSelectedUsersOnShareTab = (state, selectedUsersOnShareTab) => {
    state.selectedUsersOnShareTab = selectedUsersOnShareTab;
};

export const setAreaSubAreaByActivityId = (state, areaSubAreaByActivityId) => {
    state.areaSubAreaByActivityId = areaSubAreaByActivityId;
};

export const setCurrentCompaniesByUser = (state, currentCompaniesByUser) => {
    state.currentCompaniesByUser = currentCompaniesByUser;
};

export const setFilesUpdated = (state, file) => {
    state.statusFiles = [...state.statusFiles, file];
};

export const setFlowAlertsParams = (state, flowAlertsParams) => {
    localStorage.setItem(
        'm_flowAlertsParams',
        JSON.stringify(flowAlertsParams),
    );
    state.flowAlertsParams = flowAlertsParams;
};

export const setArrayFilesUpdated = (state, arrayFiles) => {
    let array = arrayFiles.sort((a, b) => {
        if (a.historyFile === b.historyFile) {
            return new Date(a.date) - new Date(b.date);
        } else {
            return a.historyFile === 'new' ? -1 : 1;
        }
    });
    state.statusFiles = array;
};

export const setWorkflowsWithAresaAndSubAreasInter = (
    state,
    workflowsWithAresaAndSubAreasInter,
) => {
    state.workflowsWithAresaAndSubAreasInter =
        workflowsWithAresaAndSubAreasInter;
};
export const setCompanyHolidays = (state, data) => {
    state.companyHolidays = data;
};

export const setFilesUpdatedTask = (state, file) => {
    state.statusFilesTask = [...state.statusFilesTask, file];
};

export const setArrayFilesUpdatedTask = (state, arrayFiles) => {
    state.statusFilesTask = arrayFiles;
};

export const setNotificationTabUser = (state, infoTab) => {
    state.notificationsTabUser = infoTab;
};

export const updateFilterUsersExternalsSelected = (state, filter) => {
    state.selectedUsersExternal = filter;
};
export const updateFilterExternalUsersOnlyItems = (state, filter) => {
    state.externalUsersOnlyItems = filter;
};
export const updateFilterExternalUsersCount = (state, filter) => {
    state.externalUsersCount = filter;
};

export const setExternalDataUsers = (state, filter) => {
    state.usersListExternal = filter;
};
export const setReverseToogle = (state, filter) => {
    state.reverseToogle = filter;
};
export const updateApoyoOnlyItems = (state, items) => {
    state.apoyoOnlyItems = items;
};
export const setSelectAreasByUsernewComponent = (
    state,
    areasByUsernewComponent,
) => {
    state.areasByUsernewComponent = areasByUsernewComponent;
};
export const setDynamicFieldsNames = (state, val) => {
    state.dynamicFieldsNames = val;
};

export const setUsersOutlook = (state, data) => {
    state.usersOutlook = data;
};
export const setNameClientProyect = (state, name) => {
    state.clientProyectActiveName = name;
};
export const setDynamicReports = (state, val) => {
    state.dynamicReports = val;
};
export const setSelectCompanyManagementId = (state, val) => {
    state.selectCompanyManagementId = val;
};
export const setSelectCompanyManagementName = (state, val) => {
    state.selectCompanyManagementName = val;
};

export const setSaveReasignFlag = (state, val) => {
    state.dynamicSaveReasign = val;
};
export const setChekedReasign = (state, val) => {
    state.chekedReasign = val;
};
export const setUserOldId = (state, val) => {
    state.userOldId = val;
};
export const setDocumentsByCase = (state, val) => {
    state.documentsByCase = val;
};
export const setStepsMenu = (state, menu) => {
    state.stepsMenuState = menu;
};
export const setCurrentActivityState = (state, activityState) => {
    state.activityState = activityState;
};
export const setSubAreasTariff = (state, subAreasTariff) => {
    state.subAreasTariff = subAreasTariff;
};
export const setConfirmDeactivateProyectsClients = (
    state,
    confirmDeactivateProyectsClients,
) => {
    state.confirmDeactivateProyectsClients = confirmDeactivateProyectsClients;
};

export const setConfirmDeactivateProyects = (
    state,
    confirmDeactivateProyects,
) => {
    state.confirmDeactivateProyects = confirmDeactivateProyects;
};

export const setDataClient = (state, dataClient) => {
    state.dataClient = dataClient;
};

export const setDataProyect = (state, dataProyect) => {
    state.dataProyect = dataProyect;
};

export const setStateDeactivateClient = (state, stateDeactivateClients) => {
    state.stateDeactivateClients = stateDeactivateClients;
};

export const setVerifyDeactivateClients = (state, verifyDeactivateClients) => {
    state.verifyDeactivateClients = verifyDeactivateClients;
};

export const setstateDeactivateProyects = (state, stateDeactivateProyects) => {
    state.stateDeactivateProyects = stateDeactivateProyects;
};

export const setverifyDeactivateProyects = (
    state,
    verifyDeactivateProyects,
) => {
    state.verifyDeactivateProyects = verifyDeactivateProyects;
};

export const setCreateTradeAgreementPopupFlag = (
    state,
    createTradeAgreementPopupFlag,
) => {
    state.createTradeAgreementPopupFlag = createTradeAgreementPopupFlag;
};

export const setCancelProcessTradeAgreement = (
    state,
    cancelProcessTradeAgreement,
) => {
    state.cancelProcessTradeAgreement = cancelProcessTradeAgreement;
};
export const setSucessDeleteFile = (state, sucessDeleteFile) => {
    state.sucessDeleteFile = sucessDeleteFile;
};
export const setDynamicContactsFields = (state, dynamicContactsFields) => {
    state.dynamicContactsFields = dynamicContactsFields;
};
export const setInfoCurrency = (state, val) => {
    state.infoCurrency = val;
};

export const setMessageActivateClient = (state, messageActivateClient) => {
    state.messageActivateClient = messageActivateClient;
};
export const setStateClient = (state, stateClient) => {
    state.stateClient = stateClient;
};

export const setEmailExterns = (state, emailExterns) => {
    state.emailExterns = emailExterns;
};
export const setPracticeHistoryId = (state, practiceHistoryId) => {
    state.practiceHistoryId = practiceHistoryId;
};

export const setServiceUnitHistoryId = (state, serviceUnitHistoryId) => {
    state.serviceUnitHistoryId = serviceUnitHistoryId;
};
export const setHistoryCurrencyIndexId = (state, HistoryCurrencyIndexId) => {
    state.HistoryCurrencyIndexId = HistoryCurrencyIndexId;
};
export const setProjectsByContacts = (state, projectsClient) => {
    state.projectsByClient = projectsClient;
};
export const setProjectsSelectedByContact = (state, projectsClient) => {
    state.projectsByContact = projectsClient;
};

export const setContactId = (state, contactId) => {
    state.contactIdParam = contactId;
};

export const setIdHistoryRol = (state, IdHistoryRol) => {
    state.IdHistoryRol = IdHistoryRol;
};
export const setIdHistoryUsers = (state, idHistoryUsers) => {
    state.idHistoryUsers = idHistoryUsers;
};
export const setParametersOutlook = (state, parametersOutlook) => {
    state.parametersOutlook = parametersOutlook;
};

export const setIdHistoryProyect = (state, IdHistoryProyect) => {
    state.IdHistoryProyect = IdHistoryProyect;
};
export const setMessageInactiveClient = (state, messageInactiveClient) => {
    state.messageInactiveClient = messageInactiveClient;
};
export const setShowProcessSelectBox = (state, value) => {
    state.showProcessSelectBox = value;
};
export const setIdHistoryAgreementCommercial = (
    state,
    IdHistoryAgreementCommercial,
) => {
    state.IdHistoryAgreementCommercial = IdHistoryAgreementCommercial;
};

export const setIdHistoryWorkGroup = (state, IdHistoryWorkGroup) => {
    state.IdHistoryWorkGroup = IdHistoryWorkGroup;
};

export const setUserIdReassingProcess = (state, userIdReassingProcess) => {
    state.userIdReassingProcess = userIdReassingProcess;
};
export const setIdHistoryContact = (state, IdHistoryContact) => {
    state.IdHistoryContact = IdHistoryContact;
};

export const setIdHistoryClient = (state, IdHistoryClient) => {
    state.IdHistoryClient = IdHistoryClient;
};
export const setIdHistoryTariff = (state, IdHistoryTariff) => {
    state.IdHistoryTariff = IdHistoryTariff;
};

export const setIdHistoryDynamicForm = (state, IdHistoryDynamicForm) => {
    state.IdHistoryDynamicForm = IdHistoryDynamicForm;
};

export const setIdHistoryTypeActivity = (state, IdHistoryTypeActivity) => {
    state.IdHistoryTypeActivity = IdHistoryTypeActivity;
};

export const setTaxesByCountry = (state, dataTaxes) => {
    state.taxesByCountry = dataTaxes;
};

export const setIdHistoryFlows = (state, IdHistoryFlows) => {
    state.IdHistoryFlows = IdHistoryFlows;
};

export const setIdHistoryAlertsFlows = (state, IdHistoryAlertsFlows) => {
    state.IdHistoryAlertsFlows = IdHistoryAlertsFlows;
};

export const setParamsRates = (state, paramsRates) => {
    sessionStorage.setItem('m_paramsRates', JSON.stringify(paramsRates));
    state.paramsRates = paramsRates;
};