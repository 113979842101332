import managementApi from '@/api/managementApi';
import organizationApi from '@/api/organizationApi';
import companySchema from '@/utils/models/companySchema';
import userSchema from '@/utils/models/userSchema';
import contactSchema from '@/utils/models/contactSchema';
import productionApi from '@/api/productionApi';

export const createOrganization = async ({ commit }, name) => {
    const { data } = await organizationApi.post(`Organization`, {
        tenantID: 0,
        name,
        users: [],
    });
    const company = {
        data: data.data,
        name,
    };
    commit('setCompany', company);
    commit('auth/createTenantId', company, {
        root: true,
    });
};

export const createCompany = async ({ commit }, infoCompany) => {
    const { company } = infoCompany;
    // company.generalInfo.contactEmail = [
    //     company.generalInfo.contactEmail
    //         ? company.generalInfo.contactEmail
    //         : company.email,
    // ];
    company.generalInfo.styleCompany = {
        ...companySchema.generalInfo.styleCompany,
        ...company.generalInfo.styleCompany,
    };
    const sendData = {
        ...companySchema,
        ...company,
        generalInfo: {
            ...companySchema.generalInfo,
            ...company.generalInfo,
        },
        styleCompany: {
            ...companySchema.generalInfo.styleCompany,
            ...company.generalInfo.styleCompany,
        },

        companyInformation: {
            ...companySchema.companyInformation,
            timeZone: company.companyInformation.timeZone ?? '',
            language: company.companyInformation.language ?? '',
        },
    };
    const { data } = await managementApi.post(`Companies`, sendData);
    localStorage.setItem('companyId', data.data);
    commit('management/resultPut', data, { root: true });
    commit('management/setCompanyActiveId', data.data, { root: true });
    return data.data;
};

export const createUser = async ({ commit }, userInfo) => {
    commit('updateStateLoading', true);
    const { companyId, associatedCompany } = userInfo;
    const data = {
        ...userSchema,
        name: userInfo.nameUser ?? '',
        lastName: userInfo.lastName ?? '',
        email: userInfo.email ?? '',
        city_Con: userInfo.generalInfo.city ?? '',
        country_Con: userInfo.generalInfo.country ?? '',
        associatedCompany,
        subAreas: [associatedCompany],
        tenantId: companyId,
    };
    let response = await managementApi.post(`User`, data);
    commit('management/resultPut', response.data, { root: true });
};

export const createUserNew = async ({ commit }, userInfo) => {
    commit('updateStateLoading', true);
    const data = {
        ...userSchema,
        ...userInfo,
    };
    commit('updateStateLoading', false);
    let response = await managementApi.post(`User`, data);
    commit('management/resultPut', response.data, { root: true });
};
export const putUser = async ({ commit }, userInfo) => {
    commit('updateStateLoading', true);
    const data = {
        ...userSchema,
        ...userInfo,
    };
    commit('updateStateLoading', false);
    let response = await managementApi.put(`User`, data);
    commit('management/resultPut', response.data, { root: true });
};

export const createContact = async ({ commit }, contactInfo) => {
    commit('updateStateLoading', true);
    const sendData = {
        ...contactSchema,
        ...contactInfo,
    };
    commit('updateStateLoading', false);
    const { data } = await managementApi.post(`User/CreateContact`, sendData);
    commit('management/resultPut', data, { root: true });
    return data.data;
};
export const putContact = async ({ commit }, contactInfo) => {
    commit('updateStateLoading', true);
    const sendData = {
        ...contactSchema,
        ...contactInfo,
    };
    commit('updateStateLoading', false);
    const { data } = await managementApi.put(`User/PutContact`, sendData);
    commit('management/resultPut', data, { root: true });
    return data.data;
};

export const getOrganizationsByEmailUser = async ({ commit }, emailUser) => {
    commit('updateStateLoading', true);
    await managementApi.get(`Organization/${emailUser}`);
    commit('updateStateLoading', false);
};

export const getCountries = async ({ commit }) => {
    const response = await organizationApi
        .get(`Country`)
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            } else {
                // console.log.log(response);
                return { success: false, codError: 1, data: null };
            }
        })
        .catch((error) => {
            // console.log.log(error);
            return { success: false, codError: 1, data: null };
        });
    commit('setGeneralCountries', await response.data);
    return response;
};

export const getGeneralInfoByEmail = async ({ commit }, email) => {
    let usu = localStorage.getItem('viewAs');
    usu = usu == null ? email : usu;

    const response = await managementApi
        .get(`User/GeneralInfoByEmail/${usu}`)
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            } else {
                // console.log.log(response);
                return { success: false, codError: 1, data: null };
            }
        })
        .catch((error) => {
            // console.log.log(error);
            return { success: false, codError: 1, data: null };
        });
    commit('updateStateLoading', false);
    return response;
};

export const getGeneralInfo = async ({ commit }) => {
    const { data } = await managementApi.get(
        `Companies/getDocumentsAndCountries`,
    );
    localStorage.setItem('company', JSON.stringify(data.data));
    commit('setGlobalInfo', data.data);
};

export const getTenantsByUser = async ({ commit }, emailUser) => {
    const { data } = await organizationApi.get(
        `Organization/GetTenantsByUserEmail/${emailUser}`,
    );
    commit('setTenantsByUser', data.data);
};

export const getReduceInformationByClientId = async ({ commit }, clientId) => {
    const { data } = await managementApi.get(
        `Clients/GetReduceInformationByClientId/${clientId}`,
    );
    commit('setclientData', data.data);
};

export const getReduceInformationByProjectId = async (
    { commit },
    projectId,
) => {
    const { data } = await managementApi.get(
        `Proyects/GetReduceInformationByProjectId/${projectId}`,
    );
    commit('setProjectData', data.data);
};

export const getReduceInformationByCaseId = async ({ commit }, caseId) => {
    const { data } = await productionApi.get(
        `Process/GetReduceInformationByCaseId/${caseId}`,
    );
    commit('setCaseData', data.data);
};

export const modifiedForm = async ({ commit }, isModified) => {
    sessionStorage.setItem('inititalStateModified', isModified);
};

export const getCompaniesByEmail = async ({ commit }, email) => {
    let usu = localStorage.getItem('viewAs');
    usu = usu == null ? email : usu;
    const { data } = await managementApi.get(`User/CompaniesByEmail/${usu}`);
    commit('setUserCompanies', data.data);
    return data.data;
};

export const getSessionInfoByUser = async ({ commit }, userId) => {
    const { data } = await managementApi.get(`Sessions/GetUserSession`);
    return data?.data;
};

export const getSessionById = async ({ commit }, sessionId) => {
    const { data } = await managementApi.get(`Sessions/${sessionId}`);
    return data?.data;
};

export const createSessionByUser = async (
    { commit },
    { userId, browserInfo },
) => {
    const { data } = await managementApi.post(
        `Sessions/CreateSessionByUser/${userId}/${browserInfo}`,
    );
    return data?.data;
};

export const updateSessionByUser = async ({ commit }, userId) => {
    const { data } = await managementApi.put(
        `Sessions/UpdateSessionByUser/${userId}`,
    );
    return data?.data;
};

export const deleteSessionAllUser = async ({ commit }, userId) => {
    const { data } = await managementApi.delete(
        `Sessions/DeleteSessionsByUser/${userId}`,
    );
    return data?.data;
};

export const deleteSessionById = async ({ commit }, sessionId) => {
    const { data } = await managementApi.delete(
        `Sessions/DeleteSessionById/${sessionId}`,
    );
    return data?.data;
};
