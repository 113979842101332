export const updateFilterTeam = (state, filter) => {
    state.selectedTeam = filter;
};
export const updateFilterArea = (state, filter) => {
    state.selectedArea = filter;
};
export const updateFilterCustomer = (state, filter) => {
    state.selectedCustomer = filter;
};
export const updateFilterTeamCount = (state, filter) => {
    state.selectedTeamCount = filter;
};
export const updateFilterAreaCount = (state, filter) => {
    state.selectedAreaCount = filter;
};
export const updateFilterCustomerCount = (state, filter) => {
    state.selectedCustomerCount = filter;
};
export const updateApoyoCount = (state, filter) => {
    state.selectedApoyoCount = filter;
};
export const updateFilterTime = (state, filter) => {
    localStorage.setItem('selectedTime', filter);
    state.selectedTime = filter;
};
export const updateFilterDate = (state, filter) => {
    localStorage.setItem('selectedDate', filter);
    state.selectedDate = filter;
};
export const updateFilters = (state, filters) => {
    state.filters = filters;
};
export const setlistActivities = (state, listActivities) => {
    state.listActivities = listActivities;
};
export const setlistWorkflows = (state, listWorkFlows) => {
    state.listWorkFlows = listWorkFlows;
};
export const setFilterTeams = (state, teams) => {
    state.teams = teams;
};
export const setFilterState = (state, status) => {
    state.filterState = status;
};
export const setGeneralFilterDash = (state, data) => {
    localStorage.setItem('filterDash', JSON.stringify(data));
    state.generalFilterDash = data;
};
export const setConfigSchedulerViews = (state, views) => {
    state.configSchedulerViews = views;
};
export const setFilterAreas = (state, areas) => {
    state.areas = areas;
};
export const setFilterRazonesSocial = (state, razonSocial) => {
    state.razonesSocial = razonSocial;
};

export const updateFilterTeamOnlyItems = (state, items) => {
    state.teamsOnlyItems = items;
};
export const updateFilterAreaOnlyItems = (state, items) => {
    state.areasOnlyItems = items;
};

export const updateFilterCustomerOnlyItems = (state, items) => {
    state.customersOnlyItems = items;
};
export const setGrid = (state, grid) => {
    state.grid = grid;
};

export const setMetaRangeByUser = (state, data) => {
    state.metaRangeByUser = data;
};

export const setTotalSearchActivities = (state, res) => {
    state.totalSearchActivities = res;
};
export const setTimeBillableIndication = (state, res) => {
    state.timeBillableIndication = res;
};
export const setTimeNoBillableIndication = (state, res) => {
    state.timeNoBillableIndication = res;
};
export const setHistoryOportunityIndication = (state, res) => {
    state.historyOportunityIndication = res;
};
export const setCompleteActivitiesIndication = (state, res) => {
    state.completeActivitiesIndication = res;
};
export const setAnualBillableIndication = (state, res) => {
    state.anualBillableIndication = res;
};

export const setAssigmentHoursIndication = (state, res) => {
    state.assigmentHoursIndication = res;
};
export const setAwaitingRevisionHoursIndication = (state, res) => {
    state.awaitingRevisionHoursIndication = res;
};
export const setEfficiencyIndication = (state, res) => {
    state.efficiencyIndication = res;
};

export const setlistCases = (state, listCases) => {
    state.listCases = listCases;
};

export const setWithFilters = (state, data) => {
    state.withFilters = data;
};
export const setCargandobtn = (state, data) => {
    state.cargandobtn = data;
};
export const setEjecutarFiltro = (state, data) => {
    state.ejecutarFiltro = data;
};
export const setLoadingIndicators = (state, data) => {
    state.loadingIndicators = [
        data,
        data,
        data,
        data,
        data,
        data,
        data,
        data,
        data,
    ];
};
export const setLoadingIndicator = (state, data) => {
    state.loadingIndicators[data] = false;
};
export const setCurrentGridTitle = (state, currentGridTitle) => {
    state.currentGridTitle = currentGridTitle;
};
export const setClearFilterGeneral = (state, clearFilterGeneral) => {
    state.clearFilterGeneral = clearFilterGeneral;
};
export const GD_FilterTeam = (state, filter) => {
    state.GCselectedTeam = filter;
};
export const GD_FilterTeamOnlyItems = (state, filter) => {
    state.GCteamsOnlyItems = filter;
};
export const GD_FilterTeamCount = (state, filter) => {
    state.GCselectedTeamCount = filter;
};

export const GD_FilterRZ = (state, filter) => {
    state.GCselectedRZ = filter;
};
export const GD_FilterRZOnlyItems = (state, filter) => {
    state.GCRZOnlyItems = filter;
};
export const GD_FilterRZCount = (state, filter) => {
    state.GCselectedRZCount = filter;
};
export const setTimesByUsers = (state, filter) => {
    state.timesByUsersData = filter;
};
export const setFilterForExport = (state, filter) => {
    state.filterExportActivities = filter;
};
export const setTotalSearchActivitiesInRevision = (
    state,
    totalSearchActivitiesInRevision,
) => {
    state.totalSearchActivitiesInRevision = totalSearchActivitiesInRevision;
};
